import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAutoGroups,
  resetGroup,
  setStateUpdatedAutoGroups,
} from "../../../features/groups/groupSlice";
import { Row, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { paginationOptions, DEFAULT_LIMIT } from "../../../utils/constants";
import ExpandableRowsComponent from "../ExpandableTabs/GroupExpandableRowsComponent";
import { setSelectedGroup } from "../../../features/keyword/keywordSlice";
import clickService from '../../shared/cells/ClickableCell';
import { toast } from 'react-toastify';
import keywordService from "../../../features/keyword/keywordService";

const AutoGrouping = ({ clearSelectedRows, setPaginationSetting, search, selectedTab, socket, sortData, getFilterCountApi }) => {
  const { autoGroups, autoGroupFilterCount } = useSelector((state) => state.groups);
  const dispatch = useDispatch();
  // Group Page Limit 
  const [limit, setLimit] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.group?.limit) || DEFAULT_LIMIT);
  const [page, setPage] = useState(1)
  //sorting 
  const params = useParams();
  const [action] = useState({ fromUser: false }); //this is a way to have an instant-changing state
  const [sortedObj, setSortedObj] = useState({})

  const {
    setSelectedGroupRows
  } = useSelector((state) => state.keywords);

  const columns =  [
    {
      name: "Group Name",
      selector: (row) => row.name || "N/A",
      sortable: true,
      width: "45%",
      style: { fontSize: 13, color: "#3a3a3a", padding:'0' },
      cell: (row) => <clickService.ClickableCellForGroupTabs row={row} val={row.name || "N/A"} search={search} type={'text'} saveAction={savePageName} name={"pageName"} />
    },
    {
      name: "Label",
      selector: (row) => row.label || "N/A",
      sortable: true,
      width: "15%",
      style: { fontSize: 13, color: "#3a3a3a", padding:'0'},
      // cell: (row) => <CustomCellSimple row={row} />,
    },
    {
      name: "KW #",
      selector: (row) => row.count ? row.count : 0,
      sortable: true,
      width: '6%',
      style: { fontSize: 13, color: "#3a3a3a", padding:'0' }
    },
    {
      name: "Vol",
      selector: (row) => row.totalVolume ? row.totalVolume : 0,
      sortable: true,
      width: '6%',
      style: { fontSize: 13, color: "#3a3a3a", padding:'0' }
    },
    {
      name: "Diff",
      width: '6%',
      selector: (row) => row.averageDifficulty ? parseFloat(row.averageDifficulty).toFixed(2) : 0,
      sortable: true,
      style: { fontSize: 13, color: "#3a3a3a", padding:'0' }
    },
    {
      name: "FS",
      width: '5%',
      selector: (row) => Math.round(Number(row.averageFS)),
      sortable: true,
      style: { fontSize: 13, color: "#3a3a3a", padding:'0' }
    },
    {
      name: "Vol/Diff",
      width: '6%',
      selector: (row) => row.averageVolumeRatioDiff > 0 ? parseFloat(row.averageVolumeRatioDiff).toFixed(2) : 0,
      sortable: true,
      style: { fontSize: 13, color: "#3a3a3a", padding:'0' }
    },
  ];

  const savePageName = async (data) => {
    const result = await keywordService.updateGroupedDataAction({projectId : params.id ,data})
      if (result.status) {
          toast.success("Successfully updated the document", {
            autoClose: 1000,
            position: toast.POSITION.BOTTOM_LEFT
          });
      }
      return;
  }

  useEffect(() => {
    if (selectedTab === '6')
      dispatch(getAutoGroups({ id: params.id, query: { ...search, page: page, limit: limit, sortedObj: sortedObj } }))
  }, [limit, page, search, selectedTab])

  useEffect(() => {
    sortData(sortedObj)
  }, [sortedObj])

  const sortFunction = (selector, direction) => {
    let sortBy = selector.name === 'V/D' ? 'volumeRatioDiff' : selector.name;
    let sort = { [sortBy]: direction }
    let sortingOfGkwTab = {...sortedObj, ...sort };

    dispatch(getAutoGroups({ id: params.id, query: { ...search, limit, page, sortedObj: sortingOfGkwTab} }));
    setSortedObj(prevState => ({...prevState, ...sortingOfGkwTab}));
    localStorage.setItem('sortingOfGkwTab', JSON.stringify(sortingOfGkwTab));
  }
  
  useEffect(() => {
    socket.on(`UNGROUP_CALLED` + params.id, () => {
      let sortedObj;
      let selectedTab = localStorage.getItem('selectedTab');
      let search = JSON.parse(localStorage.getItem('search'));
      let previousPage;
      setPage((prev) => {
        previousPage = prev
        return prev;
      })
      
      setSortedObj((prev) => {
        sortedObj = prev
        return prev;
      })
      
      if (selectedTab === '6') {
        getFilterCountApi(search);
        dispatch(getAutoGroups({ id: params.id, ...search, query: { ...search, page: previousPage, limit, sortedObj: sortedObj } }))
      }
    });

    socket.on(`GROUP_CREATED` + params.id, (data) => {
      let sortedObj;
      let selectedTab = localStorage.getItem('selectedTab');
      let search = JSON.parse(localStorage.getItem('search'))
      let previousPage;
      setPage((prev) => {
        previousPage = prev
        return prev;
      })
      
      setSortedObj((prev) => {
        sortedObj = prev
        return prev;
      })
      
      if (selectedTab === '6') {
        getFilterCountApi(search);
        dispatch(getAutoGroups({ id: params.id, ...search, query: { ...search, page: previousPage, limit, sortedObj: sortedObj } }))
      }
    });

    socket.on(`UPDATE_DATA` + params.id, (data) => {
      let sortedObj;
      let selectedTab = localStorage.getItem('selectedTab');
      let search = JSON.parse(localStorage.getItem('search'))
      let previousPage;
      
      setPage((prev) => {
        previousPage = prev
        return prev;
      })
      
      setSortedObj((prev) => {
        sortedObj = prev
        return prev;
      })
      
      if (selectedTab === '6') {
        getFilterCountApi(search);
        dispatch(getAutoGroups({ id: params.id, ...search, query: { ...search, page: previousPage, limit, sortedObj: sortedObj } }))
      }
    });

    socket.on("GROUP_UPDATED" + params.id, (data) => {
      dispatch(setStateUpdatedAutoGroups({data}))
    })

    return () => {
      resetGroup();
      socket.off(`UPDATE_DATA`);
      socket.off(`UNGROUP_CALLED`);
      socket.off("GROUP_CREATED");
      socket.off("GROUP_UPDATED")
    }
  }, [])

  const onSelectedGroup = (e) => {
    if (!action.fromUser) return;
    const selectedRows = e.selectedRows
    let obj = {}
    let unSelectedGroups = Object.keys(setSelectedGroupRows);

    selectedRows.forEach(el => {
      const index = unSelectedGroups.findIndex((s) => s === el.name);
      unSelectedGroups.splice(index, 1);
      obj[el.name] = el.keywords
    })
    dispatch(setSelectedGroup({...obj}))
    unSelectedGroups.forEach((el) => {
      dispatch(setSelectedGroup({ [el]: undefined}))
    })
  }

  const handleMouseEnter = () => {
    action.fromUser = true; //this was the way I found to prevent the component to clear the selection on every page render,
    //if the user is not with the mouse on a row, doesn't allow to change the selected rows
  };

  const handleMouseLeave = () => {
    action.fromUser = false; //When the users moves the mouse out of a row, block the changes to the selected rows array (line 39)
  };

  const Checkbox = React.forwardRef(({ _id, onClick, ...rest }, ref) => {

    return (
      <>
        <div
          className="form-check d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "", cursor: "pointer" }}
        >
        <label className="form-check-label" htmlFor={_id} id={`booty-check-${_id}`}>
          <input
            type="checkbox"
            className="form-check-input"
            id={_id}
            style={{ height: "30px", width: "20px", cursor: "pointer" }}
            ref={ref}
            name="selection-checks-GKws"
            onClick={onClick}
            onMouseEnter={() => action.fromUser = true}
            onMouseLeave={() => action.fromUser = false}
            {...rest}
          />
        </label>
        </div>
      </>
    );
  });

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
      <Row>
        <DataTable
          clearSelectedRows={clearSelectedRows}
          sortFunction={sortFunction}
          onChangePage={(page) => {
            setPage(page)
          }
          }
          onSelectedRowsChange={onSelectedGroup}
          onChangeRowsPerPage={(limit) => {
            setPaginationSetting("group", limit)
            setLimit(limit)
          }
          }
          columns={columns}
          data={autoGroups}
          className='keywords-table group-table'
          paginationServer
          style={{
            fontSize: 13,
          }}
          selectableRowsComponent={Checkbox}
          selectableRows
          onRowMouseEnter={handleMouseEnter}
          onRowMouseLeave={handleMouseLeave}
          paginationRowsPerPageOptions={paginationOptions}
          paginationPerPage={limit || DEFAULT_LIMIT}
          paginationTotalRows={autoGroupFilterCount}
          striped
          expandableRows
          expandableRowsComponent={ExpandableRowsComponent}
          pagination={true}
          fixedHeader
          sortServer
          onSort={sortFunction}
          customStyles={{
            expanderButton: {
              style: {
                color: '#ccc'
              }
            },
            headRow: {
              style: {
                color: '#000',
                backgroundColor: '#232323',
                fontSize: '13px',
              },
            },
            rows: {
              style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: '13px',
              },
              stripedStyle: {
                color: "#000",
                backgroundColor: "#f4f4f4",
                fontSize: '13px',
              }
            }
          }}
        />
      </Row>
    </Tab.Container>
  );
};

export default AutoGrouping

