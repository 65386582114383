import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import logsService from './logsService';

const initialState = {
  logs: [],
  isError: false,
  isSuccess: false,
  total:0,
  isLoading: false,
  selectedUser: "",
  message: '',
  filters: {
    selectOption1: '',
    selectOption2: '',
    searchQuery: '',
    limit: 100,
    page: 1
  },
};

export const getLogs = createAsyncThunk(
  'logs/getLogs',
  async (filters, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await logsService.getLogs(filters, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logs = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSelectedUser : (state, action) => {
      state.selectedUser = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.logs = action.payload.logs;
        state.total = action.payload.total;
      })
      .addCase(getLogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, updateFilters, setSelectedUser } = logs.actions;
export default logs.reducer;
