import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../../features/users/userSlice';
import { toast } from "react-toastify"
const ToggleCell = ({ row, disabled }) => {
  const [activated, setActivated] = useState(row.isActivated);
  const dispatch = useDispatch()
  const handleToggleChange = async () => {
    try {

      // Update local state
      setActivated(!activated);
      dispatch(updateUser({ _id: row._id, isActivated: !activated }));
      toast.success(`Successfully ${!activated ? 'Unblocked' : 'Blocked'} the user`, {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });      // Call the parent component's onChange function if provided
    } catch (error) {
      console.error('Error updating activation status:', error);
    }
  };

  return (
    <Form.Check
      type="switch"
      id={`custom-switch-${row._id}`}
      className="custom-switch"
      label=""
      disabled={disabled}
      checked={activated}
      onChange={handleToggleChange}
    />
  );
};

export default ToggleCell;
