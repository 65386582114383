import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { useState } from 'react'; // Import useState hook
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from '../features/auth/authSlice';
import { SidebarMenu } from './kw-grouping/components';
import Actions from './actions/Actions';
import { Container, Dropdown, DropdownButton, Button } from "react-bootstrap"; // Import Bootstrap components

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isSuccess } = useSelector((state) => state.auth);
  const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/login');
  };

  return (
    <Container fluid className='custom-padding'>
      <header className='header' style={{zIndex: 10}}>
        <div className='link-unstyled'>
          <Link to='/'>Keywords</Link>
        </div>
        {user && (
          <ul>
            {/* History button */}
            <li style={{ listStyle: 'none', marginRight: '10px' }}>
              <Actions />
              </li>
            {/* Profile icon and dropdown */}
            <li style={{ listStyle: 'none' }}>
              <DropdownButton
                id="dropdown-basic-button"
                title={<FaUser />}
                onClick={toggleDropdown} // Toggle dropdown on icon click
                show={showDropdown} // Show dropdown based on state
                variant="outline-dark" // Black outlined dropdown button
              >
                <Dropdown.ItemText>{user.role}</Dropdown.ItemText> {/* Display username */}
                <Dropdown.ItemText>{user.email}</Dropdown.ItemText> {/* Display username */}
                <Dropdown.Divider />
                {/* Add other dropdown items here */}
                <Dropdown.Item onClick={onLogout}>
                  <FaSignOutAlt /> Logout
                </Dropdown.Item>
              </DropdownButton>
            </li>
          </ul>
        )}
      </header>
      {user && <SidebarMenu user={user} isSuccess={isSuccess} />}
    </Container>
  );
}

export default Header;
