import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import keywordSlice from '../features/keyword/keywordSlice'
import projectSlice from '../features/project/projectSlice'
import uploadSlice from '../features/upload/uploadSlice'
import groupSlice from '../features/groups/groupSlice'
import tokenSlice from '../features/tokens/tokenSlice'
import labelSlice from '../features/labels/labelSlice'
import userSlice from '../features/users/userSlice'
import actionSlice from '../features/actions/actionSlice'
import logsSlice from '../features/logs/logsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectSlice,
    files: uploadSlice,
    keywords: keywordSlice,
    groups: groupSlice,
    tokens: tokenSlice,
    labels: labelSlice,
    users: userSlice,
    actions: actionSlice,
    logs:logsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }),
})

