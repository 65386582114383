import React, { useMemo, useState, useRef, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from "react-redux";
import { setSelectedGroup } from "../../../features/keyword/keywordSlice";
import { Row } from "react-bootstrap";
import Highlighter from 'react-highlight-words';

const ExpandableRowsComponent = ({ data }) => {

  const {
    setSelectedGroupRows
  } = useSelector((state) => state.keywords);

  const [action] = useState({ fromUser: false }); //this is a way to have an instant-changing state
  const mounted = useRef(false)
  const [sortedData, setSortedData] = useState(data.keywords);

  const dispatch = useDispatch();
  const CustomCellForGroupsKeywords = React.memo(({ row, search }) => {
    const searchWords = [
      ...(search.include?.split(",")),
      ...(search.filter?.split(",")),
      ];
  
      const searchWordsCaseInsensitive = searchWords.reduce((acc, word) => {
          const lowercaseWord = word.toLowerCase();
          if (!acc.includes(word)) {
              acc.push(word);
          }
          if (!acc.includes(lowercaseWord)) {
              acc.push(lowercaseWord);
          }
          return acc;
      }, []);
      
    return (
        <Row key={row} className="w-100">
            <div className="d-flex align-items-center">
            <Highlighter
                highlightClassName=""
                className="text-left"
                searchWords={searchWordsCaseInsensitive}
                autoEscape={true}
                textToHighlight={row.keyword}
                highlightTag={({ children, highlightIndex }) => {
                
                  const childValue = (children.charAt(0).toUpperCase() + children.slice(1));
            
                  const isFilter = search.filter?.split(",").includes(childValue.toLowerCase()) ||
                  search.filter?.split(",").includes(childValue);
                  const isInclude = search.include?.split(",").includes(childValue.toLowerCase()) ||
                  search.include?.split(",").includes(childValue);
                
                  let highlightStyle = {};
                
                if (isFilter) {
                    highlightStyle.backgroundColor = "#FFF3CD";
                } else if (isInclude) {
                    highlightStyle.backgroundColor = "#FFF3CD";
                }
                
                return (
                    <span style={highlightStyle}>
                    {children}
                    </span>
                );
                }}
            />
            </div>
        </Row>
    );
});
  const columns = useMemo(
    () => [
      {
        name: "Keyword",
        selector: (row) => row.keyword,
        sortable: true,
        cell: (row) => (
          <CustomCellForGroupsKeywords
              row={row}
              search={JSON.parse(localStorage.getItem('search'))}
          />),
          width: '30%',
          style: { fontSize: 11, color: "#5a5a5a", marginLeft: '30px', marginRight: '20px' },
      },
      {
        // name: "FS",
        // selector: (row) => Number(row.difficulty),
        // sortable: true,
        width: '36%',
        style: { fontSize: 11, color: "#3a3a3a"},
    },
      {
        name: "Vol",
        selector: (row) => row.isParent ? handleSumOfVolume(row) : "0",
        sortable: true,
        style: { fontSize: 11, color: "#3a3a3a", padding: 0  },
        width: '6%',

      },
      {
        name: "Diff",
        selector: (row) => row.isParent ? handleSumOfDiff(row) : "0",
        sortable: true,
        style: { fontSize: 11, color: "#3a3a3a", padding: 0  },
        width: '6%',

      },
      {
        name: "FS",
        selector: (row) => Number(row.fs),
        sortable: true,
        style: { fontSize: 11, color: "#3a3a3a", padding: 0  },
        width: '5%',

      },
      {
        name: "V/D",
        selector: (row) => row.isParent ? handleSumOfvolumeRatioDiff(row) : "0",
        sortable: true,
        width: '6%',
        style: { fontSize: 11, color: "#3a3a3a", padding: 0   }
      }
    ],
    []
  );

  const handleSumOfVolume = (obj) => {
    let sum = 0;
    data.keywords.forEach(el => {
      if (obj.keyword === el.parentKeyword) {
        if (el.hasOwnProperty('volume') && el.volume !== null) {
          sum += Number(el.volume);
        }
      }
    });
    return sum;
  };  

  const handleSumOfDiff = (obj) => {
    let sum = 0;
    data.keywords.forEach(el => {
      if (obj.keyword === el.parentKeyword) {
        if (el.hasOwnProperty('difficulty') && el.difficulty !== null) {
          sum += Number(el.difficulty);
        }
      }
    });
    return sum;
  };  

  const handleSumOfvolumeRatioDiff = (obj) => {
    let sum = 0;
    data.keywords.forEach(el => {
      if (obj.keyword === el.parentKeyword) {
        if (el.hasOwnProperty('difficulty') && el.volumeRatioDiff !== null) {
          sum += Number(el.volumeRatioDiff);
        }
      }
    });
    return Number(sum).toFixed(2);
  };  

  useEffect(() => {
    const sortedKeywords = [...data.keywords];
    sortedKeywords.sort((a, b) => b.volume - a.volume);
    setSortedData(sortedKeywords);
}, [data.keywords]);   

  const Checkbox = React.forwardRef(({ _id, onClick, ...rest }, ref) => {
    return (
      <>
        <div
          className="form-check d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "", cursor: "pointer" }}
        >
          <label className="form-check-label" htmlFor={_id} id={`booty-check-${_id}`}>
            <input
              type="checkbox"
              className="form-check-input"
              name="selection-checks"
              id={_id}
              style={{ height: "30px", width: "20px", cursor: "pointer" }}
              ref={ref}
              onClick={(e) => {
                mounted.current = true;
                onClick(e);
              }}
              // onMouseEnter={() => action.fromUser = true}
              // onMouseLeave={() => action.fromUser = false}
              {...rest}
            />
          </label>
        </div>
      </>
    );
  });


  const handleOnSelectedRowsChange = ({ selectedRows }) => {
    if (mounted.current) {
      dispatch(setSelectedGroup({ [data.name]: selectedRows }))
      // })
      mounted.current = false
    }
  }


  const rowSelectCritera = (row) => {
    return Array.isArray(setSelectedGroupRows[data.name]) ? setSelectedGroupRows[data.name]?.find(el => el._id === row._id) : undefined
  }

  return (
    <DataTable
      data={sortedData?.filter(el => el.isParent)}
      columns={columns}
      noHeader={true}
      pagination
      selectableRowsNoSelectAll={true}
      selectableRows
      selectableRowSelected={rowSelectCritera}
      selectableRowsComponent={Checkbox}
      onSelectedRowsChange={(e) => handleOnSelectedRowsChange(e)}
      striped
      style={{
        fontSize: 11,
      }}
      customStyles={{
         headRow: {
          style: {
            display:'none'
            // color: '#000',
            // backgroundColor: '#232323'
          },
        },
        rows: {
          style: {
            color: "#000",
            backgroundColor: "#fff"
          },
          stripedStyle: {
            color: "#000",
            backgroundColor: "#f4f4f4"
          }
        }
      }}
    />
  )
}

export default React.memo(ExpandableRowsComponent)