import axios from 'axios'

const API_URL = '/api/logs/'

// Get users
const getLogs = async (search, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    const response = await axios.get(API_URL + `?searchQuery=${search.searchQuery}&project=${search.project}&user=${search.user}&page=${search.page}&limit=${search.limit}`, config)
    return response.data

}



const logsService = {
    getLogs,
}

export default logsService
