import React, { useEffect } from 'react'
import LogsTable from './logs-table/LogsTable'
import { getLogs } from '../../features/logs/logsSlice'
import { useDispatch, useSelector } from 'react-redux'
import FilterSection from './FilterSection'
import { getProjects } from '../../features/project/projectSlice'
import { getUsers } from '../../features/users/userSlice'

const Logs = () => {
  const dispatch = useDispatch();
  const { selectedUser, filters } = useSelector(state => state.logs)
  useEffect(() => {
    fetchLogs()
  }, [])

  const fetchLogs = () => {
    dispatch(getProjects({ name: "", page: 1, limit: 10000, sortedObj: {} }))
    dispatch(getUsers(''))
    dispatch(getLogs(filters))
  }
  return (
    <div className='row'>
      <div className="col-2"></div>
      <div className="col-8">
        <h5>{(selectedUser !== "" && selectedUser !== 'All Users') ? `${selectedUser} log's` : 'Recent Logs'}
        </h5>
        <FilterSection />
        <LogsTable />
      </div>
      <div className="col-2">

      </div>
    </div>
  )
}

export default Logs