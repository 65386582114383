import React, { useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROLES } from '../../../utils/constants';
import { logout } from '../../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

import { FaUser, FaProjectDiagram, FaUniversalAccess, FaFileAlt, FaClipboardList } from 'react-icons/fa';

const SidebarMenu = ({ user, isSuccess }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  let projectsURL = '';
  let usersURL = '/users'
  let universalMergedURL = '/universal-merged'
  let generateH2URL = '/content'


  if (isSuccess || user) {
    projectsURL = '/projects/';
  }

  useEffect(() => {
    if (!user?.role) {
      navigate('/login')
      dispatch(logout())
    }
  }, [])

  const isOptionActive = (url) => {
    return window.location.pathname === url;
  };

  // const handleOptionClick = (event, url) => {
  //   if (isOptionActive(url)) {
  //     event.preventDefault(); // Prevent navigation if the option is already active
  //   }
  // };

  // const projectId = localStorage.getItem("RP_ID");

  return (
    <div style={{ display: 'inline', position: 'absolute' }}>
      <Button className="menu-outline" onClick={handleShow}>
        <FaBars />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Keywords</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="sidebar-custom">
          <ul>
            {user?.role !== ROLES.STAFF && (
              <li className={isOptionActive(usersURL) ? 'sidebar-active' : ''}>
                <Link onClick={() => setShow(false)} to="/users">
                  <FaUser /> Users
                </Link>
              </li>
            )}

            <li className={isOptionActive(projectsURL) ? 'sidebar-active' : ''}>
              <Link onClick={() => setShow(false)} to="/projects">
                <FaProjectDiagram /> Projects
              </Link>
            </li>
            <li className={isOptionActive(universalMergedURL) ? 'sidebar-active' : ''}>
              <Link onClick={() => setShow(false)} to="/universal-merged">
                <FaUniversalAccess /> Universal Merge
              </Link>
            </li>
            <li className={isOptionActive(generateH2URL) ? 'sidebar-active' : ''}>
              <Link onClick={() => setShow(false)} to="/content">
                <FaFileAlt /> Content
              </Link>
            </li>
            {user?.role !== ROLES.STAFF && (
              <li className={isOptionActive(generateH2URL) ? 'sidebar-active' : ''}>
                <Link onClick={() => setShow(false)} to="/logs">
                  <FaClipboardList className="icon" /> Logs
                </Link>
              </li>
            )}

          </ul>

        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SidebarMenu;