import React, { useEffect, useState } from "react";
import { AiFillCheckCircle, AiOutlineReload } from "react-icons/ai";
import { Link } from "react-router-dom";
import goalService from "../../../features/project/projectService";
import { setProjectProcess } from "../../../features/project/projectSlice";
import { useDispatch } from "react-redux";

const Breadcrumbs = ({ projectId, pageName, message, process, isSuccessUpload, triggerEffectInParent}) => {
  const [updatedMessage, setUpdatedMessage] = useState(message);
  const [updatedProcess, setUpdatedProcess] = useState(process);
  const [stepCount, setStepCount] = useState(-1);
  const [isReloadPressed, setReloadPressed] = useState(false);
  const dispatch = useDispatch();

  const handleReloadClick = () => {
    getProject();
    setReloadPressed(true);

    // Reset the rotation after a short delay
    let delay = setTimeout(() => {
      setReloadPressed(false);
    }, 500); // Adjust the delay as needed
    return () => {
        clearTimeout(delay);
    }
  };

  const getProject = async () => {
    try {
      const response = await goalService.getProject(projectId);
      console.log('response................',response);
      if (response.status) {
        dispatch(setProjectProcess(response.process));
        setUpdatedMessage(response.message);
        setUpdatedProcess(response.process);
        setStepCount(response.stepCount);
        if(response.message == "Processed" && (response.stepCount == 10 || response.stepCount == '10'))
        {
          triggerEffectInParent();
        }
      }
    } catch (error) {}
    // console.log("RESPONSE: ", response);
  };

  useEffect(() => {
    getProject();
    return () => {};
  }, [isSuccessUpload]);

  return (
    <div className="row">
      <div className="col-12 d-flex ms-2 mb-2" style={{ fontSize: "11px" }}>
        <Link className="color-blue" to={"/"}>
          {" "}
          Projects{" "}
        </Link>{" "}
        <span className="mx-2"> {">>"} </span>
        <a className="color-blue"> {pageName}</a>
      </div>
      <div className="col-12 d-flex ms-2 mb-2 justify-content-between align-items-center">
        <div>
          <span style={{ fontSize: 11, color: "black" }}> Status: </span>{" "}
          <span
            style={{
              fontWeight: "bold",
              fontSize: "13px",
              color: updatedProcess ? "#01796F" : "inherit",
              marginLeft: 5,
            }}
          >
            {" "}
            <AiFillCheckCircle />{" "}
            {updatedMessage == "Project Created" ? "Created" : updatedMessage}
          </span>{" "}
          {(updatedProcess || stepCount == -1 || updatedMessage == "Project Created") && updatedMessage !== 'Auto Processing' ? null : (
            <span
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                color: updatedProcess ? "#01796F" : "inherit",
                marginLeft: 5,
              }}
            >
              Step {stepCount} / 10 is completed
            </span>
          )}
        </div>
        <div onClick={handleReloadClick} style={{width: "5%"}}>
          <AiOutlineReload 
            className={isReloadPressed ? "rotate" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
