import { Tab, Tabs, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useCallback, useMemo } from "react"
import { cleanseKeyword } from "../../../features/keyword/keywordSlice";
import { getTokens, resetTokens, setunblockedTokens, setblockedTokens, getBlockTokens, setunMergedTokens, setFilterCurrentTokens } from "../../../features/tokens/tokenSlice";
import axios from "axios";
import qs from "qs";
import { toast } from 'react-toastify'
import Highlighter from "react-highlight-words";
import { DEFAULT_LIMIT } from "../../../utils/constants";
import { useParams } from "react-router-dom";
import { getSocket } from "../../../socket";
import tokenService from "../../../features/tokens/tokenService";
import keywordService from "../../../features/keyword/keywordService";
import { getLabels } from '../../../features/labels/labelSlice'
import { formatNumber } from "../../../utils/helper";
import DataTable from "react-data-table-component";
import { paginationOptions } from "../../../utils/constants";
import ExpandableRowsComponent from "../ExpandableTabs/NgramsExpandableRowsComponent";
import CurrenTokensTab from "./Tabs/CurrenTokensTab";
import { getGroups } from "../../../features/groups/groupSlice";
import LabelDeleteModal from "../../shared/modals/LabelDeleteModal";
import { addAction } from "../../../features/actions/actionSlice";

const socket = getSocket()

let timeoutId;
const CustomCell = React.memo(({ row, search, handleSearch }) => {
  return (
    <Highlighter
      highlightClassName=""
      className="text-right"
      style={{
        cursor: "pointer",
      }
      }
      searchWords={[
        ...search.include.split(","),
        ...search.filter.split(","),
      ]}
      onClick={() => {
        let filter = search.filter.split(",").filter((s) => s)
        if (!filter.includes(row.token)) {
          filter.push(row.token)
        }
        handleSearch({ target: { name: "filter", value: filter.join(',') } })
      }}
      autoEscape={true}
      textToHighlight={row.token}
    />
  )
})

const Checkbox = React.forwardRef(({ _id, onClick, ...rest }, ref) => {
  return (
    <>
      <div
        className="form-check d-flex justify-content-center align-items-center ngram-checkbox"
        style={{ backgroundColor: "", cursor: "pointer" }}
      >
        <label className="form-check-label" htmlFor={_id} id={`booty-check-${_id}`}>
          <input
            type="checkbox"
            id={_id} // Add id attribute
            name={`selection-check-${_id}`} // Add name attribute
            className="form-check-input"
            style={{ height: "30px", width: "20px", cursor: "pointer" }}
            ref={ref}
            onClick={onClick}
            {...rest}
          />
        </label>
      </div>
    </>
  );
});

const Ngrams = React.memo(({ projectId, search, handleSearch, setPaginationSetting, keywordWithAPI, labelSearch, labelPage, labelLimit, getCount, sortedObjUG, getPaginationTotal }) => {
  const { user } = useSelector(state => state.auth);

  let { unblockedtokens, blockedtokens, unblockCount, blockCount, filterCount, filterCurrentTokens, currentTokens, filterBlockCount, unmergedtokens } = useSelector((state) => state.tokens);
  const { labels } = useSelector((state) => state.labels)
  const { inProcess } = useSelector((state) => state.keywords)

  const [tabs, setTabs] = useState('2')

  const dispatch = useDispatch()

  const [page, setPage] = useState(1);

  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [bulkBlock, setBulkBlock] = useState(false);

  const [unblockedRows, setunblockedRows] = useState([])
  const [mergeTokens, setMergeTokens] = useState(false)

  const [limit, setLimit] = useState(DEFAULT_LIMIT)

  const [blimit, setbLimit] = useState(DEFAULT_LIMIT);
  const [bulkTokens, setBulkTokens] = useState("");
  const [bpage, setbPage] = useState(1);
  const [token, setToken] = useState('')
  const params = useParams()
  const [Ulimit] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.ungroup?.limit) || 100);
  const [Upage] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.ungroup?.page) || 1);
  const [gklimit] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.group?.limit) || 100);
  const [gkpage] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.group?.page) || 1);
  const [sortedObj, setSortedObj] = useState({})
  const [isLoadingForCreate, setIsLoadingForCreate] = useState(false);
  const [isLoadingForMerged, setIsLoadingForMerged] = useState(false);
  const [isLoadingForUnmerged, setIsLoadingForUnmerged] = useState(false);

  const { process: projectProcess } = useSelector((state) => state.projects);

  async function sortFunction(selector, direction) {
    let sortBy = selector.name === 'KW' ? 'count' : selector.name === 'Volume' ? 'volume' : 'token';
    let sort = { [sortBy]: direction }
    let sortingOfNgrams = { ...sortedObj, ...sort };
    localStorage.setItem('sortingOfNgrams', JSON.stringify(sortingOfNgrams));

    if (tabs === '0') {
      const result = await axios.post(process.env.REACT_APP_API_SERVER + `/api/tokens/getTokens/${projectId}`, { sortedObj: sortingOfNgrams, mergedTokens: mergeTokens ? 'true' : 'false', tokens: token, limit, page });
      dispatch(setunblockedTokens(result.data.tokens));
    } else {
      const result = await axios.post(process.env.REACT_APP_API_SERVER + `/api/tokens/getTokens/${projectId}/blocked`, { sortedObj: sortingOfNgrams, tokens: token, limit: blimit, page: bpage });
      dispatch(setblockedTokens(result.data.tokens));
    }

    setSortedObj(prevState => ({ ...prevState, ...sortingOfNgrams }));
  }

  const ActionCellSimple = React.memo(({ row, isBlock }) => {
    return (
      <button onClick={() => handleSingleBlock(row, isBlock)} className={`btn-block ${!isBlock && 'bg-yellow'} mb-0`}></button>
    );
  });

  const columns = useMemo(() => [
    {
      name: 'Token Name',
      selector: row => row.token,
      sortable: true,
      cell: (row) => <CustomCell
        row={row}
        search={JSON.parse(localStorage.getItem('search'))}
        handleSearch={handleSearch}
      />
    },
    {
      name: 'KW',
      selector: row => formatNumber(row.count),
      sortable: true,
      style: { fontSize: 11, color: "#3a3a3a" }
    },
    {
      name: 'Volume',
      selector: row => formatNumber(row.volume || "0"),
      sortable: true,
      style: { fontSize: 11, color: "#3a3a3a" }
    },
    {
      name: '',
      selector: row => row,
      sortable: false,
      style: { fontSize: 11, color: "#3a3a3a" },
      cell: (row) => <ActionCellSimple isBlock={true} row={row} />
    }
  ], []);

  const blcokedcolumns = useMemo(() => [
    {
      name: 'Token Name',
      selector: row => row.token,
      sortable: true,
      cell: (row) => <CustomCell
        row={row}
        search={search}
        handleSearch={handleSearch}
      />
    },
    {
      name: 'KW',
      selector: row => formatNumber(row.count),
      sortable: true,
      style: { fontSize: 11, color: "#3a3a3a" },

    },
    {
      name: 'Volume',
      selector: row => formatNumber(row.volume || "0"),
      sortable: true,
      style: { fontSize: 11, color: "#3a3a3a" },
    },
    {
      name: '',
      selector: row => row,
      sortable: false,
      cell: (row) => <ActionCellSimple isBlock={false} row={row} />

    }
  ], []);

  const handleBlockNgrams = async () => {
    let words;
    let tokens
    const blocked = (tabs == '0' || tabs === '2') ? true : false
    const allSelected = getAllSelectedRows(unmergedtokens);
    if (allSelected.length || unblockedRows.length) {
      if (blocked) {
        words = allSelected.map(el => el.token)
        tokens = allSelected.map(el => ({ id: el._id, token: el.token }))
      } else {
        words = unblockedRows.map(el => el.token)
        tokens = unblockedRows.map(el => ({ id: el._id, token: el.token }))
      }
      const payload = { word: words.join(','), projectId, cleansing: blocked, tokens }
      dispatch(addAction({ payload, actionType: blocked ? "BLOCKING" : "UNBLOCKING", title: blocked ? "Blocking" : "Unblocking", message: `You ${blocked ? "blocked" : "unblocked"} ${words.join(', ')}`}))
      dispatch(cleanseKeyword(payload))
      dispatch(setunMergedTokens({}))
      setClearSelectedRows(!clearSelectedRows)
    } else {
      toast.warning("Please select a keyword", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    setunblockedRows([])
  }


  useEffect(() => {
    dispatch(getBlockTokens({ id: projectId, query: { sortedObj, tokens: token, limit: blimit, page: bpage } }))
    dispatch(getTokens({ id: projectId, query: { sortedObj, tokens: token, limit, page } }))

    return () => {
      dispatch(resetTokens())
    }
  }, [])

  useEffect(() => {
    socket.on(`UPDATE_DATA` + params.id, (data) => {
      let previousToken;
      let mergeTokens;
      let sortOfNgrams;

      setToken(prevState => {
        previousToken = prevState
        return prevState
      })
      setMergeTokens(prevState => {
        mergeTokens = prevState
        return prevState
      })
      setSortedObj(prevState => {
        sortOfNgrams = prevState
        return prevState
      })
      dispatch(
        getTokens({
          id: params.id,
          query: { sortedObj: sortOfNgrams, mergedTokens: mergeTokens ? 'true' : 'false', tokens: previousToken, limit, page }
        })
      );
      dispatch(
        getBlockTokens({
          id: params.id,
          query: { sortedObj: sortOfNgrams, tokens: previousToken, limit: blimit, page: bpage },
        })
      );
    });

    socket.on(`MERGE_TOKENS` + params.id, () => {
      let mergeTokens;
      let previousToken;
      let sortOfNgrams;
      setToken(prevState => {
        previousToken = prevState
        return prevState
      })
      setMergeTokens(prevState => {
        mergeTokens = prevState
        return prevState
      })
      setSortedObj(prevState => {
        sortOfNgrams = prevState
        return prevState
      })

      dispatch(
        getTokens({
          id: params.id,
          query: { sortedObj: sortOfNgrams, mergedTokens: mergeTokens ? 'true' : 'false', tokens: previousToken, limit, page }
        })
      );
    });

    socket.on(`CREATE_TOKENS` + params.id, () => {
      let mergeTokens;
      let previousToken;
      let sortOfNgrams;
      setToken(prevState => {
        previousToken = prevState
        return prevState
      })
      setMergeTokens(prevState => {
        mergeTokens = prevState
        return prevState
      })
      setSortedObj(prevState => {
        sortOfNgrams = prevState
        return prevState
      })

      dispatch(
        getTokens({
          id: params.id,
          query: { sortedObj: sortOfNgrams, mergedTokens: mergeTokens ? 'true' : 'false', tokens: previousToken, limit, page }
        })
      );
    });

    return () => {
      socket.off(`MERGE_TOKENS`);
      socket.off(`UPDATE_DATA`);
      socket.off(`CREATE_TOKENS`);
    };

  }, [])

  useEffect(() => {
    if (tabs === '1') {
      dispatch(getBlockTokens({ id: projectId, query: { sortedObj, tokens: token, limit: blimit, page: bpage } }))

    }
    if (tabs === '0') {
      dispatch(getTokens({ id: projectId, query: { sortedObj, mergedTokens: mergeTokens ? 'true' : 'false', tokens: token, limit, page } }))
    }

  }, [limit, page, blimit, bpage, tabs, unblockedtokens?.length, blockedtokens?.length])

  const getAllSelectedRows = (selectedRowsPerPage) => {
    const allSelected = Object.values(selectedRowsPerPage).filter(Boolean) // remove undefined elements
      .map((obj) => Object.values(obj)).flat()
    return allSelected;
  };

  const submitMergeTokens = async () => {
    const allSelected = getAllSelectedRows(unmergedtokens);
    if (allSelected.length < 2) {
      toast.warn("Kindly select any tokens", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      // if (tabs === '0') {
      //   updateAfterOperation()
      // }      

      setClearSelectedRows(!clearSelectedRows)
      setIsLoadingForMerged(true)

      await tokenService.mergeTokens({ tokens: allSelected, projectId }, user.token);
      dispatch(addAction({ payload : {tokens: allSelected, projectId}, title: "Merging", actionType: "MERGING", message: `You merged tokens: ${ allSelected.map(el=>el.token).join(' ,')}`}))
      setIsLoadingForMerged(false)
      toast.success("Successfully Merged Tokens", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch(setunMergedTokens({}))
      dispatch(getLabels({ id: params.id, searchParams: qs.stringify({ search: labelSearch, previousLength: labels?.length, limit: labelLimit, page: labelPage }) }))
      const searching = (JSON.parse(localStorage.getItem('search')));
      const selectedTab = (JSON.parse(localStorage.getItem('selectedTab')));
      const sortingOfGkwTab = (JSON.parse(localStorage.getItem('sortingOfGkwTab')));
      if (selectedTab == '0') {
        keywordWithAPI(params.id, { ...searching, limit: Ulimit, page: Upage, sortedObj: sortedObjUG })
      } else if (selectedTab == '1') {
        dispatch(getGroups({ id: params.id, query: { ...searching, limit: gklimit, page: gkpage, sortedObj: sortingOfGkwTab } }));
      }
      await getCount()
      await getPaginationTotal()
    }
  }

  // const updateAfterOperation = () => {
  //   const keys = Object.keys(unmergedtokens);

  //   const newArr = unblockedtokens.map(
  //     (el) => {
  //       if (keys.includes(el.token)) {
  //         let keywords = el.tokens.filter(kw => !unmergedtokens[el.token]?.some(el => el._id === kw._id))
  //         if (keywords.length > 0) {
  //           return { ...el, keywords }
  //         } else {
  //           return
  //         }
  //       } else {
  //         return el;
  //       }
  //     }
  //   ).filter(Boolean)
  //   dispatch(setunblockedTokens(newArr))
  // }

  const submitUnMergeTokens = async () => {
    const allSelected = getAllSelectedRows(unmergedtokens)
    if (allSelected.length < 2) {
      toast.warn("Kindly select any tokens", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      // if (tabs === '0') {
      //   updateAfterOperation()
      // }      
      setIsLoadingForUnmerged(true)
      setClearSelectedRows(!clearSelectedRows)
      await tokenService.unMergeTokens({ tokens: allSelected, projectId }, user.token);
      dispatch(addAction({ payload : {tokens: allSelected, projectId},title: "Unmerging", actionType: "UN_MERGING", message: `You unmerged tokens: ${ allSelected.map(el=>el.token).join(' ,')}`}))
      toast.success("Successfully Un merged Tokens", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      setIsLoadingForUnmerged(false)
      dispatch(setunMergedTokens({}))
      dispatch(getLabels({ id: params.id, searchParams: qs.stringify({ search: labelSearch, previousLength: labels?.length, limit: labelLimit, page: labelPage }) }))
      const searching = (JSON.parse(localStorage.getItem('search')));
      const selectedTab = (JSON.parse(localStorage.getItem('selectedTab')));
      const sortingOfGkwTab = (JSON.parse(localStorage.getItem('sortingOfGkwTab')));
      if (selectedTab == '0') {
        keywordWithAPI(params.id, { ...searching, limit: Ulimit, page: Upage, sortedObj: sortedObjUG })
      } else if (selectedTab == '1') {
        dispatch(getGroups({ id: params.id, query: { ...searching, limit: gklimit, page: gkpage, sortedObj: sortingOfGkwTab } }));
      }
    }
  }

  const changeblocled = (e) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      dispatch(getBlockTokens({ id: projectId, query: { sortedObj, tokens: e.target.value, limit: blimit, page: bpage } }))
    }, 200)
  }

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const getExpression = (keywords) => {
    // If keywords is an array, join the elements using spaces
    const keywordsString = Array.isArray(keywords) ? keywords.join(' ') : keywords;

    // Split the input keywords by either comma or space
    const keywordsArray = keywordsString.split(/[, ]+/);

    // Escape special characters in each keyword
    const escapedKeywords = keywordsArray.map((keyword) => escapeRegExp(keyword));

    // Create the pattern by joining the escaped keywords with '|'
    const pattern = `(?:${escapedKeywords.join('|')})`;

    // Return the regular expression with global (g) and case-insensitive (i) flags
    return new RegExp(pattern, "gi");
  };

  const changeCurrentTab = (e) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      const tokens = [...currentTokens];
      let result = [];
      const regex = getExpression(e.target.value.split(','))
      if (tokens.length) {
        result = tokens.filter(el => {
          return regex.test(el.token);
        })
        result.map(el => ({ ...el, tokens: el.tokens.filter(j => regex.test(j.token)) }))
      }
      dispatch(setFilterCurrentTokens(result))
    }, 200)

  }

  const changeUnblocled = (e) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      dispatch(getTokens({ id: projectId, query: { sortedObj, mergedTokens: mergeTokens ? 'true' : 'false', tokens: e.target.value, limit, page } }))
    }, 200)
  }

  const handleSingleBlock = (element, blocked) => {
    let tokens = element?.tokens.map(el => ({ ...el, id: el._id }));
    let words = element?.tokens.map(tk => tk.token).join(',');
    setunblockedRows([])
    setClearSelectedRows(!clearSelectedRows)
    const payload = { word: words, projectId, cleansing: blocked, tokens }
    dispatch(addAction({payload, actionType: blocked ? "BLOCKING" : "UNBLOCKING", title: blocked ? "Blocking" : "Unblocking", message: `You ${blocked ? "blocked" : "unblocked "} ${words}`}))
    dispatch(cleanseKeyword(payload))
  }

  const onSelectedRowsChange = (e) => {
    const selectedRows = e.selectedRows;
    let obj = {};
    let unSelectedGroups = Object.keys(unmergedtokens);

    selectedRows.forEach((el) => {
      const index = unSelectedGroups.findIndex((s) => s === el.token); // Update to ngram property
      unSelectedGroups.splice(index, 1);
      obj[el.token] = el.tokens; // Update to ngrams property
    });

    dispatch(setunMergedTokens({ ...obj }));

    unSelectedGroups.forEach((el) => {
      dispatch(setunMergedTokens({ [el]: undefined }));
    });

  }

  function onChangePage(value, check) {
    // if(check) {
    setPage(value);
    // }
  }

  function onChangeRowsPerPage(value) {

    setPaginationSetting('unBlockngrams', value)
    setLimit(value)
  }

  const onSelectedRowsBlockChange = useCallback((e) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      setunblockedRows(e.selectedRows.flatMap(el => el.tokens));
    }, 100)
  }, [])

  function onChangeBlockPage(value, check) {
    // if(check){
    setbPage(value)
    // }
  }

  const handleBulkBlock = () => {
    if (bulkTokens.length) {
      setBulkBlock(false);
      toast.success("Bulk blocking operation starts", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      const bulkTokensWithoutSpaces = bulkTokens.replace(/\s/g, '');
      const payload = { 
        isBulkBlock: true,
        word: bulkTokensWithoutSpaces,
        projectId: params.id,
        cleansing: true,
        searchTokenByName: true,
        tokens: bulkTokensWithoutSpaces.split(',').map(el => ({ token: el }))
      }
      console.log(bulkTokens, "THis is the bulk tokens")
      dispatch(addAction({actionType: "BULK_BLOCKING", payload, title: "Bulk blocking", message: `You bulk blocked ${bulkTokens.split(',').join(' ,')}`}))
      dispatch(cleanseKeyword(payload))
    } else {
      toast.error("Please enter the tokens", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }

  function onChangeblockRowsPerPage(value) {

    setPaginationSetting('blockNgrams', value)
    setbLimit(value)
  }

  function tabChange(e) {
    setTabs(e)
  }

  const submitCreateTokens = async () => {
    setIsLoadingForCreate(true)
    let tokenArray = token?.trim().split(' ');

    if (tokenArray?.length > 1) {
      const result = await tokenService.createTokens({ tokens: tokenArray, id: projectId });

      if (result.status === true) {
        toast.success(result.message || "Tokens Created Successfully", {
          autoClose: 1000,
          position: toast.POSITION.BOTTOM_LEFT
        });
        const searching = (JSON.parse(localStorage.getItem('search')))
        keywordWithAPI(params.id, { ...searching, limit: Ulimit, page: Upage, sortedObj: sortedObjUG })
      } else {
        toast.error(result.message || "Error Occurred while Creation", {
          autoClose: 1000,
          position: toast.POSITION.BOTTOM_LEFT
        });
        return;
      }
    } else {
      toast.success("Select more than one token to create a new token", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return;
    }
    setIsLoadingForCreate(false)
  }

  // Handle click event for the checkbox
  const handleClick = (value) => {
    dispatch(getTokens({ id: projectId, query: { sortedObj, mergedTokens: `${value}`, tokens: token, limit, page } }))
    setMergeTokens(value)
  };

  return (
    <>
      <div className="col-12 d-flex">
        <div className="col-9 w-180-laptop">
          <input
            type="text"
            value={token}
            onChange={(e) => {
              // Update the "token" state with the new input value
              setToken(e.target.value);
              // Update the "createTokens" state with the new input value
              // Call the appropriate function based on the "tabs" value
              tabs === '0' ? changeUnblocled(e) : tabs === '2' ? changeCurrentTab(e) : changeblocled(e);
            }}
            placeholder="Search"
            id='ungrams-Search'
            aria-label="Search"
            className="no-radius form-control height-50"
          />
        </div>
        <div className="col-3 ms-2 w-140-laptop">
          <Button style={{ fontSize: 13 }} className="btn-black" variant={`btn btn-outline-dark height-50 no-radius ${tabs === '0' ? 'green-bg' : 'bg-yellow'}`} onClick={() => handleBlockNgrams(tabs == '0' ? true : false)}
            disabled={isLoadingForCreate || inProcess || isLoadingForMerged || isLoadingForUnmerged || !projectProcess} >
            {tabs === '1' ? 'Unblock' : 'Block'}
          </Button>
          <Button style={{ fontSize: 13, padding: '1px 1px',  }} className="btn-black" variant={`btn btn-outline-dark height-50 no-radius mt-2 bg-red`} onClick={() => setBulkBlock(true)}
            disabled={isLoadingForCreate || inProcess || isLoadingForMerged || isLoadingForUnmerged  || !projectProcess} >
            Bulk Block
          </Button>
        </div>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
          checked={mergeTokens}
          onChange={e => handleClick(e.target.checked)}
        />
        <label className="form-check-label" style={{ fontSize: '13px' }} for="flexSwitchCheckChecked">Show Merged</label>
      </div>
      <div className="d-flex">
        <Button style={{ fontSize: 13 }} variant={`btn btn-outline-dark height-50 no-radius green-bg `} className="btn-black" onClick={submitCreateTokens}
          disabled={token.length ? ((isLoadingForCreate || inProcess || isLoadingForMerged || isLoadingForUnmerged) && (token.trim().split(" ").length < 2)) : true}>  Create </Button>
        <Button style={{ fontSize: 13 }} variant={`btn btn-outline-dark height-50 no-radius green-bg `} className="btn-black ms-1" onClick={submitMergeTokens}
          disabled={isLoadingForCreate || inProcess || isLoadingForMerged || isLoadingForUnmerged || !projectProcess} >  Merge </Button>
        <Button style={{ fontSize: 13 }} variant={`btn btn-outline-dark height-50 no-radius green-bg `} className="btn-black ms-1" onClick={submitUnMergeTokens}
          disabled={isLoadingForCreate || inProcess || isLoadingForMerged || isLoadingForUnmerged || !projectProcess} >  Unmerge </Button>
      </div>
      <Tabs
        defaultActiveKey={2}
        id="fill-tab-example"
        className="my-3 projects-details-tab blocked-unblocked-tab projects-ngram-text mx-2"
        fill
        onSelect={tabChange}
      >
        <Tab eventKey={2}  className="ngrams-pagination" title={`Current (${(!token.length ? currentTokens.length : filterCurrentTokens.length) || 0})`}>
          <CurrenTokensTab token={token} columns={columns} clearSelectedRows={clearSelectedRows} filterCurrentTokens={filterCurrentTokens} currentTokens={currentTokens} />
        </Tab>
        <Tab eventKey={0} className="ngrams-pagination" title={`All (${unblockCount})`}>
          {/* <MyDataTable clearSelectedRows={clearSelectedRows} limit={limit} page={page} fromNgrams={true} ngram onSelectedRowsChange={onSelectedRowsChange} total={filterCount} sortFunction={sortFunction} myClass={'ngrams-table'} onChangePage={onChangePage} onChangeRowsPerPage={onChangeRowsPerPage} columns={columns} data={unblockedtokens} /> */}
          <DataTable
            className={`ngrams-table keywords-table ngram `}
            paginationServer
            style={{
              fontSize: 11,
            }}
            selectableRows
            selectableRowsComponent={Checkbox}
            columns={columns}
            onSort={sortFunction && sortFunction}
            data={unblockedtokens || []}
            onSelectedRowsChange={onSelectedRowsChange}
            sortServer
            clearSelectedRows={clearSelectedRows}
            pagination={true}
            fixedHeader
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationRowsPerPageOptions={paginationOptions}
            paginationPerPage={limit}
            onChangePage={onChangePage}
            paginationTotalRows={filterCount}
            expandableRows
            expandableRowsComponent={ExpandableRowsComponent}
            striped
            customStyles={{
              headRow: {
                style: {
                  color: '#000',
                  backgroundColor: '#232323'
                },
              },
              rows: {
                style: {
                  color: "#000",
                  backgroundColor: "#fff"
                },
                stripedStyle: {
                  color: "#000",
                  backgroundColor: "#f4f4f4"
                }
              }
            }}
          />
        </Tab>
        <Tab eventKey={1} className="ngrams-pagination" title={`Blocked (${blockCount})`}>
          {/* <MyDataTable clearSelectedRows={clearSelectedRows} limit={blimit} page={bpage} fromNgrams={true} ngram onSelectedRowsChange={onSelectedRowsBlockChange} total={filterBlockCount} sortFunction={sortFunction} myClass={'ngrams-table'} onChangePage={onChangeBlockPage} onChangeRowsPerPage={onChangeblockRowsPerPage} columns={blcokedcolumns} data={blockedtokens} /> */}
          <DataTable
            className={`ngrams-table keywords-table ngram `}
            paginationServer
            style={{
              fontSize: 11,
            }}
            selectableRows
            selectableRowsComponent={Checkbox}
            columns={blcokedcolumns}
            onSort={sortFunction && sortFunction}
            data={blockedtokens || []}
            onSelectedRowsChange={onSelectedRowsBlockChange}
            sortServer
            clearSelectedRows={clearSelectedRows}
            pagination={true}
            fixedHeader
            onChangeRowsPerPage={onChangeblockRowsPerPage}
            paginationRowsPerPageOptions={paginationOptions}
            paginationPerPage={limit}
            onChangePage={onChangeBlockPage}
            paginationTotalRows={filterBlockCount}
            expandableRows
            expandableRowsComponent={ExpandableRowsComponent}
            striped
            // paginationComponent={ () =>  <CustomPagination
            //   currentPage={bpage}
            //   totalPages={Math.ceil(filterBlockCount / (blimit))}
            //   paginationRowsPerPageOptions={paginationOptions}
            //   onChangePage={onChangeBlockPage}
            //   dataCount={filterBlockCount}
            //   itemsPerPage={blimit} // Pass your items per page value
            //   onItemsPerPageChange={(e) => {
            //     setPaginationSetting("ungroup", parseInt(e.target.value));
            //     setLimit(parseInt(e.target.value));
            //   }}
            // />
            // }
            customStyles={{
              headRow: {
                style: {
                  color: '#000',
                  backgroundColor: '#232323'
                },
              },
              rows: {
                style: {
                  color: "#000",
                  backgroundColor: "#fff"
                },
                stripedStyle: {
                  color: "#000",
                  backgroundColor: "#f4f4f4"
                }
              }
            }}
          />
        </Tab>
      </Tabs>
      <LabelDeleteModal setBulkTokens={setBulkTokens} handleConfirm={handleBulkBlock} isBulkBlock={bulkBlock} title={'Bulk Block'} onClose={() => setBulkBlock(false)} show={bulkBlock} text={"Enter the tokens to block them"} />
    </>
  )
})

export default Ngrams;