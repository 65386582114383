import axios from 'axios'

const API_URL = '/api/tokens/'

// GET Tokens
const getTokens = async (data) => {
  const response = await axios.post(API_URL+ 'getTokens/' + data.id , data.query)

  return response.data
}

// GET Tokens
const getBlockTokens = async (data) => {
  const response = await axios.post(API_URL +'getTokens/' + data.id + '/blocked' , data.query)

  return response.data
}

// POST MergeTokens
const mergeTokens = async (data, token) => {
  const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
}
  const response = await axios.post(API_URL + 'mergeTokens', data, config);
  return response.data
}

// POST UnMergeTokens
const unMergeTokens = async (data, token) => {
  const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
}
  const response = await axios.post(API_URL + 'unMergeTokens', data, config);
  return response.data
}
// POST MergeTokens
const createTokens = async (data) => {
  const response = await axios.post(API_URL + 'create-tokens', data);
  return response.data
}


const tokenService = {
getTokens,
getBlockTokens,
mergeTokens,
unMergeTokens,
createTokens
}

export default tokenService
