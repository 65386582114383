import React from "react";
import { Button } from "react-bootstrap";
import DateRangeButton from "./DateRangeButton";
import keywordService from "../../../features/keyword/keywordService";

const ExportSection = (props) => {
  const handleExport = async (type) => {
    // console.log('Exporting data for type:', type);
    // console.log('Tab : ', props.selectedTab);
    let response = [];
    if (props.selectedTab === "0") {
      let { keywords } = await props.ungroupExport(props.projectId, {
        ...props.search,
        export: "ungroup",
      });

      // Define specific headers
      const headers = [
        "parent keywords",
        "tokens",
        "total volume",
        "total difficulty",
        "total VolumeRatio Diff",
        "average Difficulty",
        "average VolumeRatio Diff",
        "fs",
      ];

      // Map keywords to include only specified fields
      keywords = keywords.map((keyword) => ({
        "parent keywords": keyword._id,
        tokens: keyword.tokens,
        "total volume": keyword.totalVolume,
        "total difficulty": keyword.totalDifficulty,
        "total VolumeRatio Diff": keyword.totalVolumeRatioDiff,
        "average Difficulty": keyword.averageDifficulty,
        "average VolumeRatio Diff": keyword.averageVolumeRatioDiff,
        fs: keyword.fs,
      }));

      response = convertJSONToCSV(keywords, headers);
      // console.log("selectedTababc:", keywords, headers);
    } else {
      response = await keywordService.exportData({
        type,
        projectId: props.projectId,
        sort: props.sort,
        sortBy: props.sortBy,
      });
    }

    const toExport = response.data ?? response;
    const url = window.URL.createObjectURL(new Blob([toExport]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${props.selectedName}.csv`);
    document.body.appendChild(link);
    link.click();
  };
  // Function to convert JSON to CSV string
  function convertJSONToCSV(jsonData, columnHeaders) {
    if (jsonData.length === 0) {
      return "";
    }
    // Create headers string
    const headers = columnHeaders.join(",") + "\n";

    // Map JSON data to CSV rows
    const rows = jsonData
      .map((row) => {
        // Map each row to CSV format
        return columnHeaders
          .map((field) => {
            // Format specific fields to have only two digits after the decimal point
            if (
              [
                "total VolumeRatio Diff",
                "average Difficulty",
                "average VolumeRatio Diff",
              ].includes(field)
            ) {
              return row[field] !== undefined
                ? parseFloat(row[field]).toFixed(2)
                : "";
            }
            return row[field] || "";
          })
          .join(",");
      })
      .join("\n");

    // Combine headers and rows
    return headers + rows;
  }
  return (
    <>
      {props.selectedTab === "0" && (
        // <div className="col-2 justify-content-start">
        <Button
          variant="btn btn-outline-dark green-bg  no-radius height-50"
          className="btn-black"
          style={{ fontSize: 11 }}
          onClick={() => handleExport("ungroup")}
        >
          Export Parent
        </Button>
        // </div>
      )}

      {props.selectedTab === "1" && (
        <div className="col-2 justify-content-start ">
          <Button
            variant="btn btn-outline-dark green-bg  no-radius height-50 "
            className="btn-black"
            style={{ fontSize: 11 }}
            onClick={() => handleExport("group")}
          >
            Export
          </Button>
        </div>
      )}

      {props.selectedTab === "2" && (
        <div className="col-2 justify-content-start ">
          <Button
            variant="btn btn-outline-dark green-bg  no-radius height-50 "
            className="btn-black"
            style={{ fontSize: 11 }}
            onClick={() => handleExport("ugh2s")}
          >
            Export
          </Button>
        </div>
      )}

      {props.selectedTab === "3" && (
        <div className="col-2 justify-content-start ">
          <Button
            variant="btn btn-outline-dark green-bg  no-radius height-50 "
            className="btn-black"
            style={{ fontSize: 11 }}
            onClick={() => handleExport("gh2s")}
          >
            Export
          </Button>
        </div>
      )}

      {props.selectedTab === "4" && (
        <div className="col-2 justify-content-start ">
          <DateRangeButton getH2sOrdersData={props.getH2sOrdersData} />
        </div>
      )}

      {props.selectedTab === "5" && (
        <div className="col-2 justify-content-start  visibilty-hidden">
          <Button
            variant="btn btn-outline-dark green-bg  no-radius height-50 "
            className="btn-black"
            style={{ fontSize: 11 }}
          >
            {/* <CSVLink
                            // headers={headers}
                            filename={`${props.selectedName}.csv`}
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            data={
                                props.allKeywordsCsvData}
                        > */}
            Export
            {/* </CSVLink> */}
          </Button>
        </div>
      )}
    </>
  );
};

export default ExportSection;
