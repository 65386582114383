import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { register } from '../../../features/users/userSlice';
import { ROLES } from '../../../utils/constants';
import { updateUser } from '../../../features/users/userSlice';

const UserForm = ({ show, handleClose, isEdit, selectedUser }) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    password: '',
    phoneNumber: '',
    isActivated: false,
  });

  useEffect(() => {
    if(isEdit) {
      setFormData(selectedUser);
    }
  }, [isEdit])


  const { user } = useSelector(state => state.auth)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    if (
      !formData.firstName.trim() ||
      !formData.lastName.trim() ||
      !formData.role.trim() ||
      !formData.email.trim() ||
      (!isEdit && !formData.password.trim()) || // Check password only if isEdit is true
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email.trim())
    ) {
      toast.error('Kindly fill in all fields correctly', {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return false;
    }
    
    return true;
  };
  

  const handleCreatePassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setFormData({ ...formData, password: randomPassword });
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    toast.success(`Successfully ${isEdit ? 'updated' : 'created'} the user`, {
      autoClose: 1000,
      position: toast.POSITION.BOTTOM_LEFT
    });

    // Handle form submission
    if (isEdit) {
      dispatch(updateUser({ ...formData, _id: selectedUser._id, }))
    } else {
      dispatch(register(formData))
    }
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? 'Edit Account' : 'Create Account'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} controlId="formFirstName">
            <Form.Label column sm={4} className="text-right">First Name:</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                style={{ fontSize: '12px' }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formLastName">
            <Form.Label column sm={4} className="text-right">Last Name:</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                style={{ fontSize: '12px' }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEmail">
            <Form.Label column sm={4} className="text-right">Email:</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                style={{ fontSize: '12px' }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPhone">
            <Form.Label column sm={4} className="text-right">Phone:</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                style={{ fontSize: '12px' }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formRole">
            <Form.Label column sm={4} className="text-right">Role:</Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                style={{ fontSize: '12px' }}
              >
                <option value="">Select Role</option>
                {user?.role === ROLES.SUPER_ADMIN && <option value="Admin">Admin</option>}
                <option value="Staff">Staff</option>
              </Form.Control>
            </Col>
          </Form.Group>
 
            <Form.Group as={Row} controlId="formActivated">
              <Form.Label column sm={4} className="text-right">Activated:</Form.Label>
              <Col sm={8}>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  name="isActivated"
                  checked={formData.isActivated}
                  onChange={() => setFormData({ ...formData, isActivated: !formData.isActivated })}
                />
              </Col>
            </Form.Group>
          
          {/* {
            !isEdit && ( */}
              <Form.Group as={Row} controlId="formPassword">
                <Form.Label column sm={4} className="text-right">Password:</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    style={{ fontSize: '12px' }}
                  />
                </Col>
                <Col sm={2}>
                  <Button variant="primary" onClick={handleCreatePassword}>
                    <RiLockPasswordLine />
                  </Button>
                </Col>
              </Form.Group>
            {/* ) */}
          {/* } */}

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserForm;
