import axios from 'axios'

const API_URL = '/api/users/'

// Get users
const getUsers = async (search, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL + `?search=${search}`, config)
    return response.data

}

// update users
const updateUser = async (body, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL ,body, config)
    return response.data

}

// delete users
const deleteUser = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }
    const response = await axios.delete(API_URL + id, config)
    return response.data

}


const userService = {
    deleteUser,
    getUsers,
    updateUser
}

export default userService
