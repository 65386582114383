import React, { useEffect, useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from 'react-toastify';
import { MdFileCopy } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";



const ClickableCell = ({ row, val, type, ifEdit, saveAction, name }) => {
    const [isEdit, setIsEdit] = useState(ifEdit);
    const [value, setValue] = useState(val)
    const [data, setData] = useState({ [name]: val });


    useEffect(() => {
        setValue(val)
    }, [val])

    const handleSaveAction = (e) => {
        setIsEdit(0)
        saveAction({ id: row._id, value: data })
    }

    const handleChange = (e) => {
        if (e.target.value == "" || e.target.value == 'null' || e.target.value == 'undefined') {
            toast.error('Order value must be greater than or equal to 0', {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else {
            if (type != 'number') {
                setValue(e.target.value)
                setData({ [e.target.name]: e.target.value })
            } else {
                let val = parseInt(e.target.value, 10);
                if (isNaN(val)) {
                    setValue("");
                } else {
                    val = val >= 0 ? val : "undefined";
                    setValue(val);
                    setData({ [e.target.name]: val })

                }
            }
        }
    }

    return (
        <>
            {isEdit ? (
                <Row>
                    <Col md={12} style={{ padding: "5px 0px" }}>
                        <Form.Control
                            type={type}
                            value={value}
                            style={{ fontSize: '11px' }}
                            name={name}
                            onChange={handleChange}
                            onBlur={handleSaveAction}
                        />
                    </Col>
                </Row>

            ) :
                (<div title={value} className="elipics-text" onClick={() => setIsEdit(1)}>
                    {value}
                </div>
                )}
        </>

    )
}

const ClickableCellForLabels = ({ row, val, type, ifEdit, selectedRowId, saveAction, name }) => {
    const [isEdit, setIsEdit] = useState(ifEdit);
    const [value, setValue] = useState(val)
    const [data, setData] = useState({ [name]: val });

    useEffect(() => {
        setIsEdit(ifEdit)
    }, [ifEdit])

    useEffect(() => {
        setValue(val)
    }, [val, row.name])

    const handleSaveAction = (e) => {
        if (data.name !== '') {
            saveAction({ id: row._id, value: data })
        }
        else {
            toast.warn("Please enter label name", {
                autoClose: 1000,
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }

    const handleChange = (e) => {
        setValue(e.target.value)
        setData({ [e.target.name]: e.target.value })
    }

    return (
        <>
            {(isEdit && row._id === selectedRowId) ? (
                <Row>
                    <Col md={12} style={{ padding: "5px 10px" }}>
                        <Form.Control
                            type={type}
                            value={value}
                            style={{ fontSize: '11px' }}
                            name={name}
                            onChange={handleChange}
                            onBlur={handleSaveAction}
                        />
                    </Col>
                </Row>

            ) :
                (<div title={value} className="elipics-text">
                    {value}
                </div>
                )}
        </>

    )
}

const CustomCellForGroups = React.memo(({ value, search }) => {
    const searchWords = [
        ...(search.include?.split(",")),
        ...(search.filter?.split(",")),
    ];

    const searchWordsCaseInsensitive = searchWords.reduce((acc, word) => {
        const lowercaseWord = word.toLowerCase();
        if (!acc.includes(word)) {
            acc.push((word.charAt(0).toUpperCase() + word.slice(1)));
        }
        if (!acc.includes(lowercaseWord)) {
            acc.push(lowercaseWord);
        }
        return acc;
    }, []);

    return (
        <div >
            <Highlighter
                highlightClassName=""
                className="text-left p-0"
                searchWords={searchWordsCaseInsensitive}
                autoEscape={true}
                textToHighlight={value}
                highlightTag={({ children, highlightIndex }) => {

                    const childValue = (children.charAt(0).toUpperCase() + children.slice(1));

                    const isFilter = search.filter?.split(",").includes(childValue.toLowerCase()) ||
                        search.filter?.split(",").includes(childValue);
                    const isInclude = search.include?.split(",").includes(childValue.toLowerCase()) ||
                        search.include?.split(",").includes(childValue);

                    let highlightStyle = {};

                    if (isFilter || isInclude) {
                        highlightStyle.backgroundColor = "#FFF3CD";
                    }

                    return (
                        <span style={highlightStyle}>
                            {children}
                        </span>
                    );
                }}
            />
        </div>
    );
});

const ClickableCellForGroupTabs = ({ row, val, type, saveAction, name }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(val)
    const [data, setData] = useState({ [name]: val })

    useEffect(() => {
        setValue(val)
    }, [val])

    const handleSaveAction = () => {
        setIsEdit(false)
        saveAction({ id: row._id, value: data })
    }
    const handleChange = (e) => {
        if (type != 'number') {
            if (e.target.value == "" || e.target.value == "null" || e.target.value == "undefined") {
                setValue("")
            } else {
                setValue(e.target.value)
                setData({ [e.target.name]: e.target.value })
            }
        }
    }
    const handleDoubleClick = () => {
        setIsEdit(true);
    };

    const handleClick = () => {
        // Copy the value of row.name to the clipboard
        navigator.clipboard.writeText(row.name)
            .then(() => {
                toast.success('Copied Successfully', {
                    autoClose: 1000,
                    position: toast.POSITION.BOTTOM_LEFT
                });
            })
            .catch((error) => {
            });
    };

    return (
        <div style={{ display: 'flex', position: 'relative' }}>
            <div style={{ width: "400px" }}>
                {isEdit ? (
                    <Row className="p-0">
                        <Col md={12} style={{ padding: '5px 0px' }}>
                            <Form.Control
                                type={type}
                                value={value}
                                style={{ fontSize: '11px' }}
                                name={name}
                                onChange={handleChange}
                                onBlur={handleSaveAction}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col md={11}>
                            <div
                                title={value}
                                onDoubleClick={handleDoubleClick}
                                style={{ cursor: 'pointer' }}
                            >
                                <CustomCellForGroups
                                    value={value}
                                    search={JSON.parse(localStorage.getItem('search'))}
                                />

                            </div>
                        </Col>

                        <Col md={1}>
                            <MdFileCopy className="copy-icon" onClick={handleClick}
                                style={{ cursor: 'pointer', width: '50px' }} />
                        </Col>

                    </Row>
                )}
            </div>

        </div >
    )
}

const ClickableCellForProject = ({ row, val, type, saveAction, name }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(val);
    const [data, setData] = useState({ [name]: val });
    const [tempVal, setTempVal] = useState(val);
    const inputRef = useRef(null);
    const navigate = useNavigate()
    const [timeoutId, setTimeoutId] = useState(null);

    console.log('the value of temVal is ', tempVal)

    useEffect(() => {
        setValue(val);
    }, [val]);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (inputRef.current && !inputRef.current.contains(event.target)) {
            setIsEdit(false);
            setValue(tempVal)
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    


      const handleKeyDown = (e) => {
        const trimmedValue = e.target.value.trim();
    
        switch (e.key) {
            case 'Enter':
                if (trimmedValue !== '') {
                    handleSaveAction();
                } else {
                    setValue(val);
                }
                setIsEdit(false);
                break;
    
            case 'Escape':
                setIsEdit(false);
                break;
    
            default:
                break;
        }
    };
    

    const handleSaveAction = () => {
        if( value.trim() === '' || data.name.trim() === '' || data[name].trim() === ''){
            setIsEdit(false)
        }
        else {

            setIsEdit(false);
            saveAction({ id: row._id, value: data });
        }
    };

    const handleChange = (e) => {
        setTempVal(e.target.value)
        if (type !== 'number') {
            if (
                e.target.value === '' ||
                e.target.value === 'null' ||
                e.target.value === 'undefined'
            ) {
                setValue('');
            } else {
                setValue(e.target.value);
                setData({ id: row._id, [e.target.name]: e.target.value });
            }
        }
    };

    const handleDoubleClick = () => {
        clearTimeout(timeoutId);
        setIsEdit(true);
    };

    const handleSingleClick = () => {
        clearTimeout(timeoutId);
        setTimeoutId(setTimeout(() => {
            const url = `/projects/${row._id}/${row.text.replaceAll(' ', '-')}`;
            if (!isEdit) {
                navigate(url)
            }
        }, 400));
    };
    const handleClick = (row) => {
        // Copy the value of row.name to the clipboard
        navigator.clipboard.writeText(row.text)
            .then(() => {
                toast.success('Copied Successfully', {
                    autoClose: 1000,
                    position: toast.POSITION.BOTTOM_LEFT
                });
            })
            .catch((error) => {
            });
    };

    return (
        <div className="row" style={{ width: '100%' }}>
            <div className="col-md-12">
                {isEdit ? (
                    <Row>
                        <Col md={12} style={{ padding: '5px 0px' }}>
                            <Form.Control
                                type={type}
                                value={value}
                                style={{ fontSize: '11px' }}
                                ref={inputRef}
                                name={name}
                                onChange={handleChange}
                                onBlur={handleSaveAction}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                    </Row>
                ) : (
                    <div className="d-flex">
                        <div style={{ alignItems: 'left' }}>
                            <MdFileCopy onClick={() => handleClick(row)}
                                style={{ cursor: 'pointer', width: '50px' }} />
                        </div>
                        <div
                            title={"Double click to edit"}
                            onDoubleClick={handleDoubleClick}
                            onClick={handleSingleClick}
                            style={{ cursor: 'pointer', color: '#0066cc' }}
                        >
                            {value || 'N/A'}
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
}

const clickService = {
    ClickableCell,
    ClickableCellForGroupTabs,
    ClickableCellForProject,
    ClickableCellForLabels
}

export default clickService