import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { setSearch } from '../../../features/users/userSlice';
import { FaUserPlus } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import UserForm from '../userForm/UserForm';

const FilterSection = () => {
    const [show, setShow] = useState(false)
    const dispatch = useDispatch();

    const onChange = (e) => {
        dispatch(setSearch(e.target.value));
    }

    return (
        <div className='py-2' >
            <form className='row justify-content-between' action="" >
                <div className="col-6 d-flex flex-start">
                    <input id="user-management-search" className='form-input' style={{ fontSize: 12, width: "100%" }} type="text" placeholder='Search user' onChange={onChange} />
                </div>
                <div className="col-3 d-flex justify-content-end">
                    <Button variant='secondary bg-black' onClick={() => setShow(true)} style={{ fontSize: '12px', padding: '3px 10px' }}>
                        <FaUserPlus /> Add user
                    </Button>
                </div>
            </form>
            {show && <UserForm show={show} handleClose={() => setShow(false)} />}
        </div>
    )
}

export default FilterSection