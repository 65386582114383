import React, { useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri';
import { deleteUser } from '../../../../features/users/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import UserForm from '../../userForm/UserForm';
import { ROLES } from '../../../../utils/constants';
import LabelDeleteModal from '../../../shared/modals/LabelDeleteModal';

const ActionButtonsCell = ({ row }) => {
  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const handleDelete = () => {
    setDeleteModal(false)
    // Handle delete action
    dispatch(deleteUser(row._id))
  };

  const handleEdit = () => {
    setShow(true)
    // Handle edit action
  };


  const deleteTooltip = (
    <Tooltip id="tooltip-delete">Delete</Tooltip>
  );

  const editTooltip = (
    <Tooltip id="tooltip-edit">Edit</Tooltip>
  );
  return (
    <>
      {(user?.role === ROLES.SUPER_ADMIN || user?._id === row._id || row.role.name === ROLES.STAFF) && (
        <div>
        <OverlayTrigger overlay={deleteTooltip}>
            <div style={{ display: 'inline-block' }}>
              <RiDeleteBin6Line
                onClick={() => setDeleteModal(true)}
                style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}
              />
            </div>
          </OverlayTrigger>
          
  
          <OverlayTrigger overlay={editTooltip}>
            <div style={{ display: 'inline-block' }}>
              <RiEdit2Line
                onClick={handleEdit}
                style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
              />
            </div>
          </OverlayTrigger>
          {show && <UserForm show={show} selectedUser={{ ...row, role: row.role.name }} isEdit={true} handleClose={() => setShow(false)} />}
         {deleteModal &&  <LabelDeleteModal title={"Delete Modal"} text={"Are you sure to delete the user?"} show={deleteModal} onClose={() => setDeleteModal(false)} handleConfirm={handleDelete} />}

        </div>
      )}
    </>
  );
  
};

export default ActionButtonsCell;
