import { useState, useEffect } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }

    if (isSuccess || user) {
      navigate('/projects')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <div className='heading border border-dark rounded d-flex flex-column justify-content-center align-items-center w-50 py-5'>
        <h1 >
          <FaSignInAlt /> Login
        </h1>
        <p>Login and start making keywords</p>
        <div className='d-flex justify-content-center align-items-center'>
          <section >
            <form className='w-100' onSubmit={onSubmit}>
              <div className='form-group'>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  name='email'
                  value={email}
                  placeholder='Enter Email'
                  onChange={onChange}
                />
              </div>
                <input
                  type='password'
                  className='form-control'
                  id='password'
                  name='password'
                  value={password}
                  placeholder='Enter password'
                  onChange={onChange}
                />
              <div className='form-group'>
                <button type='submit' className='btn btn-dark'>
                  Submit
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Login
