import React, { useEffect } from 'react';
import UsersMangement from '../components/user-management/UsersMangement';
import { useSelector } from 'react-redux';
import { ROLES } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
const Users = () => {
  const { user } = useSelector(state => state.auth)
  const navigate = useNavigate()
  useEffect(() => {
    if (!user || user?.role === ROLES.STAFF) {
      navigate("/projects")
    }
  })

  return (
    <div className='text-center'>
      <b>
        Users Management
      </b>

      <UsersMangement />
    </div>
  );
};

export default Users;