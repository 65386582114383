import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import DataTable from "react-data-table-component";
import ActionButtonsCell from './custom-cells/ActionButtonsCell';
import ToggleCell from './custom-cells/BlockUnblockCell';
import { ROLES } from '../../../utils/constants'
const UserTable = () => {
    const { users } = useSelector(state => state.users);
    const { user } = useSelector(state => state.auth)

    const columns = useMemo(() => {
        const baseColumns = [
            {
                name: "First Name",
                selector: (row) => row.firstName,
                style: { color: "#3a3a3a" },
            },
            {
                name: "Last Name",
                selector: (row) => row.lastName,
                style: { color: "#3a3a3a" }
            },
            {
                name: "Role",
                selector: (row) => row.role.name,
                style: { color: "#3a3a3a" }
            },
            {
                name: "Email",
                selector: (row) => row.email,
                style: { color: "#3a3a3a" }
            },
            {
                name: "Actions",
                selector: (row) => <ActionButtonsCell row={row} />,
                style: { color: "#3a3a3a" }
            }
        ];

        // Conditionally add the "Activated" column and "Actions" column if user role is SUPER_ADMIN
        // if (user?.role === ROLES.SUPER_ADMIN) {
            baseColumns.push(
                {
                    name: "Activated",
                    selector: (row) => <ToggleCell disabled={row.role.name === ROLES.ADMIN && user.role === ROLES.ADMIN} row={row} />,
                    style: { color: "#3a3a3a"  }
                },
            );
        // }
        return baseColumns;
    }, [user]);

    return (
        <div>
            <DataTable
                columns={columns}
                data={users}
                className="user-management-table"
                striped
                customStyles={{
                    headRow: {
                        style: {
                            color: '#000',
                            backgroundColor: '#232323'
                        },
                    },
                    rows: {
                        style: {
                            color: "#000",
                            backgroundColor: "#fff"
                        },
                        stripedStyle: {
                            color: "#000",
                            backgroundColor: "#f4f4f4"
                        }
                    }
                }}
            />

        </div>
    )
}

export default UserTable