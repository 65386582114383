import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilters, getLogs, setSelectedUser } from '../../features/logs/logsSlice';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const FilterSection = () => {
    const dispatch = useDispatch();
    const { filters, isLoading, isError, logs } = useSelector((state) => state.logs);
    const { users } = useSelector(state => state.users)
    const { projects } = useSelector(state => state.projects)
    useEffect(() => {
        // Fetch logs when component mounts or filters change
        dispatch(getLogs(filters));
    }, [dispatch, filters]);

    const handleSelectChange = (selectedOption, name) => {
        const value = selectedOption.value; // Extract the value from the selected option object
        const updatedFilters = { ...filters, [name]: value };
        if(name === 'user') {
            dispatch(setSelectedUser(selectedOption.label))
        }
        dispatch(updateFilters(updatedFilters));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedFilters = { ...filters, [name]: value };
        dispatch(updateFilters(updatedFilters));
    };

    const getSelectedOption = (obj, filter) => {
        if (!obj) {
            if (filter === 'project') {
                return { label: 'All Projects', value: '' }
            } else {
                return { label: 'All Users', value: '' }
            }
        } else {
            if (filter === 'project') {
                return { label: obj.text, value: obj._id }
            } else if (filter === 'user') {
                return { label: `${obj.firstName} ${obj.lastName}`, value: obj._id }
            }
        }
    }
    return (
        <div>
            <Form>
                <Row className='my-3'>
                    <Col xs={6}>
                        <Form.Group controlId="searchQuery">
                            <Form.Control type="text" name="searchQuery" value={filters.searchQuery} onChange={handleInputChange} placeholder="Search..." style={{ fontSize: '12px' }} />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="projectsSelect">
                            <Select
                                className='logs-select'
                                name="project"
                                value={getSelectedOption(projects.find(project => project._id === filters.project) || '', 'project')}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'project')}
                                options={[{ label: 'All Projects', value: '' }, ...projects.map(project => ({ label: project.text, value: project._id }))]}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="usersSelect">
                            <Select
                                name="user"
                                className='logs-select'
                                value={getSelectedOption(users.find(user => user._id === filters.user) || '', 'user')}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'user')}
                                options={[{ label: 'All Users', value: '' }, ...users.map(user => ({ label: `${user.firstName} ${user.lastName}`, value: user._id }))]}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            {isLoading && <p>Loading...</p>}
            {isError && <p>Error occurred: {isError}</p>}
            {logs && logs.map(log => (
                <div key={log.id}>
                    {/* Render log data */}
                </div>
            ))}
        </div>
    );
};

export default FilterSection;
