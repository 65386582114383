import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Login from "./pages/Login";
import Keywords from "./pages/Keywords";
import "bootstrap/dist/css/bootstrap.min.css";
import Users from "./pages/Users";
import UniversalMerged from "./pages/Universal-Merge";
import GernerateH2Tabs from "./components/generateContent/GenerateContentTabs";
import GeneratePages from "./components/generateContent/GenerateContentPages";
import LogsPage from "./pages/LogsPage";

function App(props) {
  return (
    <>
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/projects" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/universal-merged" element={<UniversalMerged />} />
            <Route
              path={`/content`}
              element={<GeneratePages />}
            />
            <Route
              path={`/logs`}
              element={<LogsPage />}
            />
            <Route
              path={`/content/:id`}
              element={<GernerateH2Tabs />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route
              path="/projects/:id/:name"
              element={<Keywords props={props} />}
            />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
