import React from 'react'
import Logs from '../components/logs/Logs'

const LogsPage = () => {
  return (
    <div>
      <Logs />
    </div>
  )
}

export default LogsPage