import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../features/users/userSlice'
import UserTable from './userTable/UserTable'
import FilterSection from './filter-section/FilterSection'

const UsersMangement = () => {
  const { search } = useSelector(state => state.users)
  const dispatch = useDispatch()
  useEffect(() => {
    fetchUsers(search)
  }, [search])

  const fetchUsers = (name = "") => {
    dispatch(getUsers(name))
  }

  return (
    <div className='row'>
      <div className="col-2">

      </div>
      <div className="col-8">
        <FilterSection fetchUsers={fetchUsers} />
        <UserTable />
      </div>
      <div className="col-2">

      </div>
    </div>
  )
}

export default UsersMangement