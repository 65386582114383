import axios from 'axios'

const API_URL = '/api/groups/'


// Get user goals
const getGroups = async (data) => {

  const response = await axios.post(API_URL+"getGroups/" + data.id ,  data.query )

  return response.data
}

// Get auto groups
const getAutoGroups = async (data) => {

  const response = await axios.post(API_URL+"getGroups/auto/" + data.id ,  data.query )

  return response.data
}

// Get user goals
const getGroupsNames = async (data) => {

  const response = await axios.get(API_URL + "existing-names/" +  data.id  )

  return response.data
}

// Get ug H2sData
const getUgH2s = async (data) => {

  const response = await axios.get(API_URL + "getUgh2s/" + data.id + "?" + data.query )

  return response.data
}

// Get group H2sData
const getGroupH2s = async (data) => {

  const response = await axios.get(API_URL + "getGrouph2s/" + data.id + "?" + data.query )

  return response.data
}

const setUnGroupedDataAction = async (body) => {

    const response = await axios.post(API_URL + 'setGroupedData', body)
    
    return response.data
}


const getCSVDataforUgH2s = async (id) => {

  const response = await axios.get(API_URL + 'csvDataUgH2s/'+ id)
  
  return response.data
}

const getCSVDataforGroupH2s = async (id) => {

  const response = await axios.get(API_URL + 'csvDatagH2s/' + id)
  
  return response.data
}

const effiecientGrouping = async (data, token) => {
  const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
}
  const response = await axios.post(API_URL + 'groupEfficiently', data, config)
  
  return response.data
}

 const effiecientGroupingForGh2s = async (data, token) => {
  const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
}
  const response = await axios.post(API_URL + 'groupEfficientlyForGh2s', data, config)
  
  return response.data
}

const groupService = {
  getGroups,
  getAutoGroups,
  setUnGroupedDataAction,
  getUgH2s,
  getGroupH2s,
  getCSVDataforUgH2s,
  effiecientGroupingForGh2s,
  effiecientGrouping,
  getCSVDataforGroupH2s,
  getGroupsNames
}

export default groupService
