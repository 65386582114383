import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAction, deleteExpiredActions } from '../../features/actions/actionSlice';
import { cleanseKeyword, setGroupedDataAction } from '../../features/keyword/keywordSlice';
import tokenService from '../../features/tokens/tokenService';
import { setProjectProcess } from '../../features/project/projectSlice';
import { setUnGroupedDataAction } from '../../features/groups/groupSlice';
import { FaList } from 'react-icons/fa'; // Import FaList for the icon

const Actions = () => {
    const dispatch = useDispatch();
    const actions = useSelector(state => state.actions.actions);
    const { user } = useSelector(state => state.auth);
    const [expiredActionIds, setExpiredActionIds] = useState([]);
    const [showActions, setShowActions] = useState(false); // State to toggle action list
    const [latestAction, setLatestAction] = useState(null);

    useEffect(() => {
        // Update latest action whenever actions array changes
        if (actions.length > 0) {
            setLatestAction(actions[0]);
        } else {
            setLatestAction(null);
        }
    }, [actions]);

    useEffect(() => {
        if (actions.length > 0) {
            const interval = setInterval(() => {
                dispatch(deleteExpiredActions());
            }, 60000); // Check for expired actions every 20 seconds

            return () => clearInterval(interval);
        }
    }, [dispatch, actions.length]);

    const handleDeleteAction = async (action) => {
        if (action.actionType === 'BULK_BLOCKING') {
            dispatch(cleanseKeyword({ ...action.payload, cleansing: false }))
        } else if (action.actionType === 'UN_MERGING') {
            dispatch(setProjectProcess(false))
            await tokenService.mergeTokens(action.payload, user.token);
            dispatch(setProjectProcess(true))
        } else if (action.actionType === 'MERGING') {
            dispatch(setProjectProcess(false))
            await tokenService.unMergeTokens(action.payload, user.token);
            dispatch(setProjectProcess(true))
        } else if (action.actionType === 'BLOCKING') {
            dispatch(cleanseKeyword({ ...action.payload, cleansing: false }))
        } else if (action.actionType === 'UNBLOCKING') {
            dispatch(cleanseKeyword({ ...action.payload, cleansing: true }))
        } else if (action.actionType === 'GROUPING') {
            const { name, label, ...rest } = action.payload
            dispatch(setUnGroupedDataAction(rest))
        } else if (action.actionType === 'UNGROUPING') {
            dispatch(setGroupedDataAction({ ...action.payload, isUndo: true }))
        } else if (action.actionType === 'CONFIRM_KWS') {
            dispatch(setUnGroupedDataAction({ ...action.payload }))
        } else if (action.actionType === 'UNCONFIRM_KWS') {
            dispatch(setGroupedDataAction({ ...action.payload }))
        } else if (action.actionType === 'GROUP_H2S') {
            dispatch(setUnGroupedDataAction({ ...action.payload }))
        } else if (action.actionType === 'UNGROUP_H2S') {
            dispatch(setGroupedDataAction({ ...action.payload, isUndo: true }))
        } else if (action.actionType === 'CONFIRM_H2S') {
            dispatch(setUnGroupedDataAction({ ...action.payload, isUndo: true }))
        } else if (action.actionType === 'UNCONFIRM_H2S') {
            dispatch(setGroupedDataAction({ ...action.payload }))
        }
        dispatch(deleteAction(action.id));
        setExpiredActionIds([...expiredActionIds, action.id]);
    };

    return (
        <div className={`actions-container ${showActions ? 'show' : ""}`}>
            <button className="toggle-actions-button" onClick={() => setShowActions(!showActions)}>
                <FaList className="actions-icon" /> History {/* Add the icon */}
            </button>
            {showActions ? (
                <div className="actions-list">
                    {latestAction ? (
                        <div className={`action-item`}>
                            <div className='row align-self-center w-100'>
                                <div className="action-details col-10 d-inline">
                                    <div className='px-1 align-self-center row'>
                                        <span className="action-type">{latestAction.title}</span>
                                    </div>
                                    <div className='px-1 align-self-center row'>
                                        <span className="action-payload">{latestAction.message}</span>
                                    </div>
                                </div>
                                <div className='col-2 px-2 align-self-center'>
                                    <button
                                        className="undo-button m-0"
                                        onClick={() => handleDeleteAction(latestAction)}
                                        disabled={expiredActionIds.includes(latestAction.id)}
                                    >
                                        Undo
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p className='no-actions-message mb-0'>No actions performed</p>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default Actions;
