import React, { useMemo } from 'react'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { printFormattedDateTime, truncateString } from '../../../utils/helper';
import { updateFilters } from '../../../features/logs/logsSlice';
import { paginationOptionsUGKws } from '../../../utils/constants';

const LogsTable = () => {
    const { logs, filters, total } = useSelector(state => state.logs);
    const dispatch = useDispatch();

    const columns = useMemo(() => {
        const baseColumns = [
            {
                name: "Date",
                selector: (row) => printFormattedDateTime(row.createdAt),
                style: { color: "#3a3a3a" },
                width: '15%'
            },
            {
                name: "Action Name",
                selector: (row) => row.actionName,
                style: { color: "#3a3a3a" },
                width: '20%'

            },
            {
                name: "Description",
                selector: (row) => truncateString(row.description, 200),
                style: { color: "#3a3a3a" },
                width: '65%'
            },

            // {
            //     name: "Email",
            //     selector: (row) => row.email,
            //     style: { color: "#3a3a3a" }
            // },
            // {
            //     name: "Actions",
            //     selector: (row) => <ActionButtonsCell row={row} />,
            //     style: { color: "#3a3a3a" }
            // }
        ];
        return baseColumns;
    }, []);

    const onChangePage = (page) => {
        dispatch(updateFilters({ ...filters, page }))
    }

    const onChangeRowsPerPage = (limit) => {
        dispatch(updateFilters({ ...filters, limit }))
    }


    return (
        <div>
            <DataTable
                columns={columns}
                data={logs}
                className="user-management-table logs"
                pagination
                paginationTotalRows={total}
                paginationPerPage={filters.limit}
                fixedHeader
                striped
                paginationRowsPerPageOptions={paginationOptionsUGKws}
                paginationServer
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                customStyles={{
                    headRow: {
                        style: {
                            color: '#000',
                            backgroundColor: '#232323'
                        },
                    },
                    rows: {
                        style: {
                            color: "#000",
                            backgroundColor: "#fff",
                            whiteSpace: 'break-spaces'
                        },
                        stripedStyle: {
                            color: "#000",
                            backgroundColor: "#f4f4f4"
                        }
                    },
                    cells: {
                        style: {
                          textAlign: 'left' // Align all cells to the left by default
                        },
                        rightAlign: { // Custom style for right-aligned cells
                          textAlign: 'right'
                        }
                    }
                }}
            />

        </div>
    )
}

export default LogsTable