import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { createProject, getProjects, reset } from '../features/project/projectSlice'
import { resetUpload } from '../features/upload/uploadSlice'
import { Container } from 'react-bootstrap'
import DataTable from "react-data-table-component";
import clickService from './shared/cells/ClickableCell';
import goalService from "../features/project/projectService";
import { AiFillCheckCircle, AiFillDelete, AiOutlineClear, AiOutlineFolderView } from 'react-icons/ai'
import { ImSpinner10 } from 'react-icons/im'
import DeleteConfirmation from './shared/modals/DeleteModal'
import keywordService from '../features/keyword/keywordService'
import { formatNumber } from '../utils/helper';
import { DEFAULT_LIMIT, paginationOptions } from '../utils/constants'
import FileLogModal from './shared/modals/FileLogModal'

let timeoutId;
const ProjectList = (props) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [showModal, setShowModal] = useState(false);

  const { isSuccessUpload, isErrorUpload } = useSelector((state) => state.files)

  const { projects, totalCount, isError, message } = useSelector((state) => state.projects)

  const { user } = useSelector((state) => state.auth)



  const [name, setProjectName] = useState("");

  const [search, setSearch] = useState("");

  const [sortedObj, setSortedObj] = useState("");

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [page, setPage] = useState(1)

  const [limit, setLimit] = useState((JSON.parse(localStorage.getItem('paginationSetting'))?.projectList?.limit) || DEFAULT_LIMIT);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  const [isRowId, setRowId] = useState("");
  const [isRowName, setRowName] = useState("");
  const [selectedRow, setSelectedRow] = useState();

  const openDeleteModal = (row) => {
    setRowId(row._id)
    setRowName(row.text)
    setIsDeleteModalOpen(true)
  };

  const openFileLogsModal = (row) => {
    setRowId(row._id);
    setRowName(row.text);
    setSelectedRow(row);
    setIsLogModalOpen(true)
  };

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.text,
      sortable: true,
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "left" },
      width: "350px",
      cell: (row) => (
        <clickService.ClickableCellForProject
          row={row}
          val={row.text || "N/A"}
          type={'text'}
          saveAction={savePageName}
          name={"name"}
        />
      )
    },
    {
      name: "Date Created",
      selector: (row) => {
        const date = row.createdAt ? new Date(row.createdAt) : new Date();
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false, // Set to use 24-hour time format
        };
        return date.toLocaleString("en-US", options).replace(",", ""); // Remove comma after date
      },
      width: "210px",
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
    },
    {
      name: "KW #",
      selector: (row) => formatNumber(parseInt(row.cleansedCount + row.groupedCount +
        row.groupedH2sCount+ (row.autoGroupedCount || 0 )+ row.keywordsCount + row.orderH2sCount +
        row.ugH2sCount) || 0),
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
    }, {
      name: "Pages #",
      selector: (row) => formatNumber(row.pages || 0),
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
    }, {
      name: "KW Vol.",
      selector: (row) => formatNumber(row.volume || 0),
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
    },
    {
      name: "Avg. Diff.",
      selector: (row) => formatNumber(row.avgDifficulty?.toFixed(2) || 0),
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
    },
    {
      name: "Status",
      selector: (row) => row.message || "N/A",
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
      cell: (row) => (
        <div className="" style={{ fontSize: "11px", color: row.process ? "#01796F" : "inherit" }}>
          {row.process ? (
            <>
              <AiFillCheckCircle /> {row.message}
            </>
          ) : (
            <>
              {row.message?.length > 0 && (
                <span style={{color : '11px'}}>
                  <ImSpinner10 /> {row.message === 'Project Created' ? 'Created' : row.message}
                </span>
              )}
            </>
          )}
        </div>
      )
    },
    {
      name: "Logs",
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
      cell: (row) => (
        <div
          className=""
          style={{ fontSize: "11px", cursor: "pointer", color: "#3a3a3a" }}
          onClick={() => openFileLogsModal(row)}
        >
         <AiOutlineFolderView /> Logs
        </div>
      )
    },
    {
      name: "Action",
      style: { fontSize: 11, color: "#3a3a3a", textAlign: "right" },
      cell: (row) => (
        <div
          className=""
          style={{ fontSize: "11px", cursor: "pointer", color: "#E3242B" }}
          onClick={() => openDeleteModal(row)}
        >
          <AiFillDelete /> Delete
        </div>
      )
    },
    {
      name: "Cleansing Keywords",
      style: { fontSize: 11, color: "#3a3a3a" },
      cell: (row) => (
        <div
          className=""
          style={{ fontSize: "13px", cursor:"pointer" }}
          onClick={() => cleansKeywords(row._id)}
        >
          <AiOutlineClear /> Click to Cleanse
        </div>
      )
    }    
  ];

  const cleansKeywords = async (id) => {
    keywordService.cleanseKeywords(id)
  }

  const savePageName = async (data) => {
    const result = await goalService.updateProject({ id: data.id, value: data.value.name })
    if (result.status) {
      dispatch(getProjects({ name: "", page: 1, limit: 10000, sortedObj: {} }))
      toast.success('Successfully updated the Project', {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    return;
  }
  

  const handleChange = (e) => {
    clearTimeout(timeoutId);
    setSearch(e.target.value);


    timeoutId = setTimeout(async () => {
      dispatch(getProjects({ name: e.target.value, sortedObj, page: page, limit: limit }))
    }, 150);
  };

  async function sortFunction(selector, direction) {
    const sortBy = "text"
    const order = direction === "asc" ? 1 : -1
    let sort = { [sortBy]: order }
    let sortingOfProject = { ...sortedObj, ...sort };
    setSortedObj(prevState => ({ ...prevState, ...sortingOfProject }));
    dispatch(getProjects({ name: search, sortedObj: sortingOfProject, page: page, limit: limit }))
  }

  const handleFileSubmit = async () => {
    if (!name.length) {
      toast.error("Please enter name", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      return
    }
    dispatch(createProject(name))
    setProjectName("")
  };

  useEffect(async() => {
    const RP_ID = localStorage.getItem('RP_ID')
    if(RP_ID){
      const result = await goalService.updateProjectList({id: RP_ID})
    }
    dispatch(getProjects({ name: search, page: page, limit: limit, sortedObj }))
    return () => {
      dispatch(reset())
    }
  }, [page, limit])

  useEffect(() => {
    if (isError) {
    }

    if (isErrorUpload) {
      toast.error("Error uploading the file", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
      dispatch(resetUpload())
    }

    if (!user) {
      navigate('/login')
    }


    return () => {
      dispatch(resetUpload())
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch])

  useEffect(() => {
    if (isErrorUpload) {
      toast.error("Error uploading the file", {
        autoClose: 1000,
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }, [isSuccessUpload, isErrorUpload])

  function setPaginationSetting(name, value) {
    let paginationSetting = JSON.parse(localStorage.getItem('paginationSetting'))
    paginationSetting[name] = { ...paginationSetting[name], limit: value };
    localStorage.setItem('paginationSetting', JSON.stringify(paginationSetting))
  }

  return (
    <Container className='mt-5'>
      <div className='row project-screen'>
        <div className="col-8 d-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            id='projectName'
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            onChange={handleChange}
          />
          <input
            type="text"
            className="form-control "
            placeholder="Project Name"
            value={name}
            id='projectName'
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            onChange={(e) => setProjectName(e.target.value)}
          />
          <button className="btn btn-outline-secondary create-project-btn" type="button" onClick={handleFileSubmit} id="button-addon2">Create Project +</button>
        </div>
      </div>
      <div className="row pt-2" style={{ display: 'flex', justifyContent: 'center', }}>
        <DataTable
          columns={columns}
          data={projects}
          className='projects-table'
          fixedHeader
          style={{
            fontSize: 11,
            margin: '0 auto',
          }}
          onChangePage={(page) => {
            setPage(page)
          }
          }
          onChangeRowsPerPage={(limit) => {
            setPaginationSetting("projectList", limit)
            setLimit(limit)
          }
          }
          onSort={sortFunction}
          sortServer
          paginationServer
          paginationRowsPerPageOptions={paginationOptions}
          paginationPerPage={limit || DEFAULT_LIMIT}
          paginationTotalRows={totalCount}
          striped
          paginationDefaultPage={page}
          pagination
          customStyles={{
            expanderButton: {
              style: {
                color: '#ccc'
              }
            },
            headRow: {
              style: {
                color: '#000',
                backgroundColor: '#232323',
                position: 'sticky'
              },
            },
            rows: {
              style: {
                color: "#000",
                backgroundColor: "#fff"
              },
              stripedStyle: {
                color: "#000",
                backgroundColor: "#f4f4f4"
              }
            },
            cells: {
              style: {
                textAlign: 'left' // Align all cells to the left by default
              },
              rightAlign: { // Custom style for right-aligned cells
                textAlign: 'right'
              }
            }
          }}
          centered // Align columns to the center
        />
        {isDeleteModalOpen == true && <DeleteConfirmation showModal={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} 
          id={isRowId} name={isRowName} search={search} page={page} limit={limit} sortedObj={sortedObj} />}
          {isLogModalOpen == true && <FileLogModal show={isLogModalOpen} onClose={() => setIsLogModalOpen(false)} selectedRow={selectedRow}/>}
      </div>
      {/* <div className="row mt-5 pb-5">
            {
              projects.map((project) => {
                return (
                <Col md={3} className='gy-2  '>
                  <Link to={`/projects/${project._id}/${project.text.replaceAll(" ", "-")}`}>
                  <div className='p-5 project rounded'>
                    <span className=" link-unstyled fs-4 link-unstyle">
                      {project.text}
                      </span><br /><br />
                      <span className="message-status">
                        {project.process ? (
                          <>
                            <AiFillCheckCircle /> {project.message} 
                          </>
                        ) : (
                          <>
                            {project.message?.length > 0 && (
                              <span>
                                <ImSpinner10 /> {project.message}
                              </span>
                            )}
                          </>
                        )}
                  </span>
                  </div>
                  </Link>
                </Col>
                )
              })
            }
      </div> */}
    </Container>
  )
}

export default ProjectList