// slice.js
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  actions: []
};

const actionSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    addAction: (state, action) => {
      const id = uuidv4();
      const createdAt = Date.now();
      state.actions.unshift({ ...action.payload, id, createdAt }); // Insert at the beginning
    },
    deleteAction: (state, action) => {
      const actionToDelete = state.actions.findIndex(el => el.id === action.payload)
      state.actions.splice(actionToDelete, 1);
    },
    deleteExpiredActions: (state) => {
      const currentTime = Date.now();
      const result = state.actions;
      state.actions = result.filter(action => (currentTime - action.createdAt) < 120000);
    }
  }
});

export const { addAction, deleteAction, deleteExpiredActions } = actionSlice.actions;
export default actionSlice.reducer;
