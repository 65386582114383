import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import projectService from './projectService'

const initialState = {
  projects: [],
  totalCount: 0,
  process: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  cleansedKeywords : []
}

// Create new goal
export const createProject = createAsyncThunk(
  'projects/create',
  async (goalData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await projectService.createProject(goalData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Get user goals
export const getProjects = createAsyncThunk(
  'projects/getAll',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await projectService.getProjects(data, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user goals
export const getCleansedWords = createAsyncThunk(
  'projects/getCleansedKeywords',
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.user.token
      return await projectService.getCleansedWords(id)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const projecSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reset: (state) => initialState,
    setProjectProcess:(state, action) => {
      state.process = action.payload;
    }
  },
  setProjects:(state, action) => {
    state.projects = action.payload.projects;
    state.totalCount = action.payload.total;
  },

  extraReducers: (builder) => {
    builder
      .addCase(createProject.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.projects.unshift(action.payload)
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getProjects.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.projects = action.payload.projects; // Update to use projects array from payload
        state.totalCount = action.payload.total; // Store total count in the state
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getCleansedWords.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCleansedWords.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.cleansedKeywords = action.payload.cleansedKeywords
      })
      .addCase(getCleansedWords.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, setProjects,setProjectProcess } = projecSlice.actions
export default projecSlice.reducer