import React from 'react'
import { Modal, Button, Form } from "react-bootstrap";

const LabelDeleteModal = ({ show, handleConfirm, isBulkBlock, title, text, onClose, setBulkTokens}) => {
  return (
    <Modal show={show}>
    <Modal.Header closeButton onClick={() => onClose()}>
        <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="alert alert-danger">
          { text }
        </div>
          {
            isBulkBlock && <Form.Control onChange={(e) => setBulkTokens(e.target.value)} placeholder='Enter tokens to block' as="textarea" rows={3} />
          }
    </Modal.Body>
    <Modal.Footer>
        <Button variant="default" closeButton onClick={() => onClose()}>
        NO
        </Button>
        <Button variant="danger" onClick={() => {
            handleConfirm();
        }}>
        Yes
        </Button>
    </Modal.Footer>
    </Modal>
  )
}

export default LabelDeleteModal